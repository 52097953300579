import Choice from '../../common/Choice';
import Input from '../../common/Input';
import Card from '../../common/Card';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCalender from '../../common/Datepicker';
import { getPercentagePrice, getTaxExcludedPrice, getTaxIncludedPrice, getUnitPrice } from '../../../utils/helpers/formula';
import { useDispatch, useSelector } from 'react-redux';
import { fetchExpandSalesFloors, fetchPackingCategories, fetchSaleTypes } from '../../../redux/actions/api';
import { FRESHNESS_REASON_TYPE, REPORT_FLAG, TAX_INCLUDED_TYPE } from '../../../utils/constants/string';
import { ChangeRequestCompetitorCreateRequest } from '../../../services';
import { formattedDate } from '../../../utils/helpers/date';

const CardPriceSearch = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const storage = useSelector((state) => state.storage);
    const { isCompetitor, isFreshness, isInstruction } = storage;

    const { formData, setFormData, taxRate, capacityOfUnit, quantityOfUnit, discountedLowestSellingPrice, isRegistration } = props;
    const constants = useSelector((state) => state.constants);
    const { expandSalesFloors, packingCategories, saleTypes } = constants;

    useEffect(() => {
        dispatch(fetchExpandSalesFloors());
        dispatch(fetchPackingCategories());
        dispatch(fetchSaleTypes());
    }, [dispatch]);

    const [state, setState] = useState({
        discountedRequestSellingPrice: formData.discountedRequestSellingPrice || '',
        discountedRequestSellingPriceTax: '',
        sellingUnitPrice: '',
        sellingUnitPriceTax: '',
        taxIncludedType: 'excluded',
        salesStartDate: formData.salesStartDate || formattedDate(new Date()),
        salesEndDate: formData.salesEndDate || '',
        otherSpecialReports: formData.otherSpecialReports || '',
        expandSalesFloor: formData.expandSalesFloor || '',
        packingCategory: formData.packingCategory || '',
        otherPackingCategory: formData.otherPackingCategory || '',
        saleType: formData.saleType || '',
        otherSaleType: formData.otherSaleType || '',
        isCheckSalesStartDate: false,
        isCheckSellingPrice: false,
        isCheckSalesEndDate: false,

        isCheckDiscountRate: false,
        discountRate: '',
        reportFlag: !!formData.reportFlag,
        freshnessReasonType: formData.freshnessReason === '賞味期限間近' ? '賞味期限間近' : 'その他',
        freshnessReason: formData.freshnessReason !== '賞味期限間近' ? formData.freshnessReason : '',
    });

    const {
        discountedRequestSellingPrice,
        discountedRequestSellingPriceTax,
        sellingUnitPrice,
        sellingUnitPriceTax,
        taxIncludedType,
        salesStartDate,
        salesEndDate,
        expandSalesFloor,
        packingCategory,
        saleType,
        isCheckSalesStartDate,
        isCheckSellingPrice,
        isCheckSalesEndDate,
        otherSpecialReports,

        isCheckDiscountRate,
        discountRate,
        reportFlag,
        freshnessReasonType,
        freshnessReason,
    } = state;

    const [stateDataDatePicker, setStateDataDatePicker] = useState({
        minStartDate: new Date(),
        maxStartDate: null,
        minEndDate: new Date(),
        maxEndDate: new Date(new Date().setDate(new Date().getDate() + 7)),
    });
    const { minStartDate, maxStartDate, minEndDate, maxEndDate } = stateDataDatePicker;

    useEffect(() => {
        // salesEndDateを初期化
        handleChangeInput('salesEndDate', '');

        if (isFreshness) {
            setStateDataDatePicker({
                minStartDate: new Date(),
                maxStartDate: null,
                // 開始日から1週間後まで選択可能
                minEndDate: salesStartDate ? new Date(salesStartDate) : new Date(),
                maxEndDate: salesStartDate ? new Date(new Date(salesStartDate).setDate(new Date(salesStartDate).getDate() + 7)) : null,
            });
        } else {
            switch (saleType) {
                case ChangeRequestCompetitorCreateRequest.saleType.DAILY:
                    setStateDataDatePicker({
                        minStartDate: new Date(),
                        maxStartDate: null,
                        // 開始日と翌日のみ選択可能
                        minEndDate: salesStartDate ? new Date(salesStartDate) : new Date(),
                        maxEndDate: salesStartDate
                            ? new Date(new Date(salesStartDate).setDate(new Date(salesStartDate).getDate() + 1))
                            : null,
                    });
                    break;
                case ChangeRequestCompetitorCreateRequest.saleType.WEEKLY:
                    setStateDataDatePicker({
                        minStartDate: new Date(),
                        maxStartDate: null,
                        // 開始日から1週間後まで選択可能
                        minEndDate: salesStartDate ? new Date(salesStartDate) : new Date(),
                        maxEndDate: salesStartDate
                            ? new Date(new Date(salesStartDate).setDate(new Date(salesStartDate).getDate() + 7))
                            : null,
                    });
                    break;
                case ChangeRequestCompetitorCreateRequest.saleType.WEEKEND:
                    // salesStartDateが金、土、日でなければ初期化
                    if (salesStartDate && ![5, 6, 0].includes(new Date(salesStartDate).getDay())) {
                        handleChangeInput('salesStartDate', '');
                    }
                    setStateDataDatePicker({
                        minStartDate: new Date(),
                        maxStartDate: null,
                        // 開始日から3日間選択可能（週末でない場合はDatepickerで無効化）
                        minEndDate: salesStartDate ? new Date(salesStartDate) : new Date(),
                        maxEndDate: salesStartDate
                            ? new Date(new Date(salesStartDate).setDate(new Date(salesStartDate).getDate() + 3))
                            : null,
                    });
                    break;
                case ChangeRequestCompetitorCreateRequest.saleType.MONTHLY:
                    setStateDataDatePicker({
                        minStartDate: new Date(),
                        maxStartDate: null,
                        // 開始日を含む月末まで選択可能
                        minEndDate: salesStartDate ? new Date(salesStartDate) : new Date(),
                        maxEndDate: salesStartDate
                            ? new Date(new Date(salesStartDate).getFullYear(), new Date(salesStartDate).getMonth() + 1, 0)
                            : null,
                    });
                    break;
                case ChangeRequestCompetitorCreateRequest.saleType.OTHER:
                    setStateDataDatePicker({
                        minStartDate: new Date(),
                        maxStartDate: null,
                        // 開始日から14日以内
                        minEndDate: salesStartDate ? new Date(salesStartDate) : new Date(),
                        maxEndDate: salesStartDate
                            ? new Date(new Date(salesStartDate).setDate(new Date(salesStartDate).getDate() + 14))
                            : null,
                    });
                    break;
                default:
                    break;
            }
        }
    }, [saleType, salesStartDate]);

    useEffect(() => {
        handleSellingPriceChange('discountedRequestSellingPrice', discountedRequestSellingPrice);
    }, []);

    const handleSellingPriceChange = (field, value) => {
        //init value blank
        let valueInput = String(value);

        switch (field) {
            case 'discountedRequestSellingPrice':
                calculateRequestSellingPriceTax(valueInput);
                break;
            case 'discountedRequestSellingPriceTax':
                calculateRequestSellingPrice(valueInput);
                break;
            case 'discountRate':
                if (valueInput.match(/^[0-9]+$/)) {
                    const discountedRequestSellingPrice = getPercentagePrice(discountedLowestSellingPrice, valueInput);
                    valueInput = String(discountedRequestSellingPrice);
                    calculateRequestSellingPriceTax(valueInput);
                }
                if (valueInput === '') {
                    // 割引率が未入力となった場合は初期化
                    calculateRequestSellingPriceTax('');
                }
                break;
            default:
                break;
        }
    };

    const calculateRequestSellingPriceTax = (valueInput) => {
        let sellingPrice = '';
        let sellingUnitPrice = '';
        let discountedRequestSellingPriceTaxVal = '';
        let sellingUnitPriceTaxVal = '';

        if (valueInput.match(/^[0-9]+$/)) {
            sellingPrice = valueInput;
            sellingUnitPrice = getUnitPrice(sellingPrice, capacityOfUnit, quantityOfUnit);
            if (sellingUnitPrice === Number.POSITIVE_INFINITY || isNaN(sellingUnitPrice)) {
                sellingUnitPrice = 0;
            }

            discountedRequestSellingPriceTaxVal = getTaxIncludedPrice(sellingPrice, taxRate);
            sellingUnitPriceTaxVal = getUnitPrice(discountedRequestSellingPriceTaxVal, capacityOfUnit, quantityOfUnit);

            if (sellingUnitPriceTaxVal === Number.POSITIVE_INFINITY || isNaN(sellingUnitPriceTaxVal)) {
                sellingUnitPriceTaxVal = 0;
            }
        }
        setState((prevState) => ({
            ...prevState,
            discountedRequestSellingPrice: sellingPrice,
            sellingUnitPrice: sellingUnitPrice,
            discountedRequestSellingPriceTax: discountedRequestSellingPriceTaxVal,
            sellingUnitPriceTax: sellingUnitPriceTaxVal,
        }));
        setFormData((prevState) => ({
            ...prevState,
            discountedRequestSellingPrice: sellingPrice,
        }));
    };

    const calculateRequestSellingPrice = (valueInput) => {
        let sellingUnitPriceVal = '';
        let sellingPriceVal = '';
        let sellingUnitPriceTaxVal = '';
        let discountedRequestSellingPriceTax = '';

        if (valueInput.match(/^[0-9]+$/)) {
            discountedRequestSellingPriceTax = valueInput;
            sellingUnitPriceTaxVal = getUnitPrice(discountedRequestSellingPriceTax, capacityOfUnit, quantityOfUnit);
            if (sellingUnitPriceTaxVal === Number.POSITIVE_INFINITY || isNaN(sellingUnitPriceTaxVal)) {
                sellingUnitPriceTaxVal = 0;
            }
            sellingPriceVal = getTaxExcludedPrice(discountedRequestSellingPriceTax, taxRate);
            sellingUnitPriceVal = getUnitPrice(sellingPriceVal, capacityOfUnit, quantityOfUnit);

            if (sellingUnitPriceVal === Number.POSITIVE_INFINITY || isNaN(sellingUnitPriceVal)) {
                sellingUnitPriceVal = 0;
            }
        }
        setState((prevState) => ({
            ...prevState,
            discountedRequestSellingPrice: sellingPriceVal,
            sellingUnitPrice: sellingUnitPriceVal,
            discountedRequestSellingPriceTax: discountedRequestSellingPriceTax,
            sellingUnitPriceTax: sellingUnitPriceTaxVal,
        }));
        setFormData((prevState) => ({
            ...prevState,
            discountedRequestSellingPrice: sellingPriceVal,
        }));
    };

    const handleChangeInput = (field, value) => {
        if (formData[field] !== undefined) {
            setFormData((prevState) => ({ ...prevState, [field]: value }));
        }
        if (field === 'otherPackingCategory' || field === 'otherSaleType') {
            setFormData((formData) => ({
                ...formData,
                [field]: value,
            }));
        }
        if (field === 'freshnessReasonType') {
            if (value === '賞味期限間近') {
                setFormData((formData) => ({
                    ...formData,
                    freshnessReason: '賞味期限間近',
                }));
            } else {
                setFormData((formData) => ({
                    ...formData,
                    freshnessReason: freshnessReason,
                }));
            }
        }
        setState((prevState) => ({ ...prevState, [field]: value }));
    };

    return (
        <div className="okc-card okc-result-form">
            <Card type="modal_card">
                {(isCompetitor || isInstruction) && (
                    // 売場展開
                    <div className="row okc-card__row">
                        <div className="col-lg-3 okc-result-form__title">
                            <p>{t('card_registration.sales_floor_development')}</p>
                        </div>
                        <div className="col-lg-7 offset-lg-1 flex-column">
                            <div className="row no-gutters w-100 js-okc-validate">
                                <div className="form-check col-lg-2 pr-lg-3">
                                    <Choice
                                        id="expandSalesFloor"
                                        column="col-xl-3 col-lg-4 col-md-5"
                                        type="radio"
                                        data={expandSalesFloors || []}
                                        onChange={(value) => handleChangeInput('expandSalesFloor', value[0])}
                                        checked={expandSalesFloor}
                                        value={expandSalesFloor || ''}
                                    />
                                </div>
                            </div>
                            <p className="col-12 okc-error mt-2 mb-0 d-none">{t('card_registration.placeholder.validate.sale_type')}</p>
                        </div>
                    </div>
                )}
                {
                    // 売価
                    <div className="row okc-card__row">
                        <div className="col-lg-3 okc-result-form__title">
                            <p>売価</p>
                        </div>
                        <div className="col-lg-7 offset-lg-1 flex-column">
                            <div className="row no-gutters w-100 js-okc-validate mb-3">
                                <div className="form-check col-lg-2 pr-lg-3">
                                    <Choice
                                        id="taxIncludedType"
                                        column="col-xl-3 col-lg-4 col-md-5"
                                        type="radio"
                                        data={TAX_INCLUDED_TYPE}
                                        onChange={(value) => handleChangeInput('taxIncludedType', value[0])}
                                        checked={taxIncludedType}
                                        value={taxIncludedType || ''}
                                    />
                                </div>
                            </div>
                            {isRegistration ? (
                                <>
                                    <div className="row no-gutters w-100 flex-xl-nowrap">
                                        <div className="col-xl-6 align-items-start mb-2 mb-xl-0 d-flex flex-column">
                                            <p className="mb-2">{t('card_registration.price.excluding_tax.title')}</p>
                                            <div className="row no-gutters w-100 flex-xl-nowrap">
                                                <div className="col-4 flex-column js-okc-validate">
                                                    <Input
                                                        className={`form-control form-control--input input__price 
                                                    ${isCheckSellingPrice ? 'form-control--error' : ''}`}
                                                        id="input-5"
                                                        name="discountedRequestSellingPrice"
                                                        type="text"
                                                        placeholder="売価"
                                                        autocomplete="off"
                                                        maxLength="6"
                                                        onChange={(value) => handleChangeInput('discountedRequestSellingPrice', value)}
                                                        onBlur={(value) => handleSellingPriceChange('discountedRequestSellingPrice', value)}
                                                        value={discountedRequestSellingPrice || ''}
                                                        disabled={discountRate || taxIncludedType === 'included'}
                                                    />
                                                    {isCheckSellingPrice && (
                                                        <>
                                                            <p className="okc-error mt-2 mb-0">
                                                                {t('card_registration.placeholder.validate.competitive_price')}
                                                            </p>
                                                        </>
                                                    )}
                                                </div>
                                                <div className="col-1 align-items-start">
                                                    <p className="m-0 w-100 text-center mt-2">{t('card_registration.circle')}</p>
                                                </div>
                                                <div className="col-4 flex-column">
                                                    <Input
                                                        className="form-control form-control--input form-control--readonly result__price"
                                                        id="input-6"
                                                        name="sellingUnitPrice"
                                                        type="text"
                                                        placeholder={t('card_registration.placeholder.unit_price')}
                                                        readonly="readonly"
                                                        autocomplete="off"
                                                        onChange={(value) => handleChangeInput('sellingUnitPrice', value)}
                                                        value={sellingUnitPrice || ''}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-auto align-items-start">
                                                    <p className="m-0 w-100 mt-2">&nbsp;{t('card_registration.circle_unit')}</p>
                                                </div>
                                                <div className="col-1"></div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 align-items-start mb-2 mb-xl-0 d-flex flex-column">
                                            <p className="mb-2">{t('card_registration.price.including_tax.title')}</p>
                                            <div className="row w-100 flex-xl-nowrap">
                                                <div className="col-4 flex-column">
                                                    <Input
                                                        className="form-control form-control--input form-control--readonly input__tax-price"
                                                        id="input-51"
                                                        type="text"
                                                        name="discountedRequestSellingPriceTax"
                                                        placeholder="売価"
                                                        readonly="readonly"
                                                        autocomplete="off"
                                                        maxLength="6"
                                                        disabled={discountRate || taxIncludedType === 'excluded' || !taxIncludedType}
                                                        onChange={(value) => handleChangeInput('discountedRequestSellingPriceTax', value)}
                                                        onBlur={(value) =>
                                                            handleSellingPriceChange('discountedRequestSellingPriceTax', value)
                                                        }
                                                        value={discountedRequestSellingPriceTax || ''}
                                                    />
                                                </div>
                                                <div className="col-1 align-items-start">
                                                    <p className="m-0 w-100 text-center mt-2">{t('card_registration.circle')}</p>
                                                </div>
                                                <div className="col-4 flex-column">
                                                    <Input
                                                        className="form-control form-control--input form-control--readonly result__tax-price"
                                                        id="input-61"
                                                        type="text"
                                                        name="sellingUnitPriceTax"
                                                        placeholder={t('card_registration.placeholder.unit_price')}
                                                        readonly="readonly"
                                                        autocomplete="off"
                                                        onChange={(value) => handleChangeInput('sellingUnitPriceTax', value)}
                                                        value={sellingUnitPriceTax || ''}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-auto align-items-start">
                                                    <p className="m-0 w-100 mt-2">&nbsp;{t('card_registration.circle_unit')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="row w-100">
                                        <div className="col-lg-6 align-items-start">
                                            <div className="row no-gutters w-100">
                                                <p className="col-12 mb-2">{t('card_registration.price.excluding_tax.title')}</p>
                                                <div className="col-5 flex-column js-okc-validate">
                                                    <Input
                                                        className={`form-control form-control--input input__price 
                                                        ${isCheckSellingPrice ? 'form-control--error' : ''}`}
                                                        id="input-5"
                                                        name="discountedRequestSellingPrice"
                                                        type="text"
                                                        placeholder="売価"
                                                        autocomplete="off"
                                                        maxLength="6"
                                                        onChange={(value) => handleChangeInput('discountedRequestSellingPrice', value)}
                                                        onBlur={(value) => handleSellingPriceChange('discountedRequestSellingPrice', value)}
                                                        value={discountedRequestSellingPrice || ''}
                                                        disabled={discountRate || taxIncludedType === 'included'}
                                                    />
                                                    {isCheckSellingPrice && (
                                                        <>
                                                            <p className="okc-error mt-2 mb-0">
                                                                {t('card_registration.placeholder.validate.competitive_price')}
                                                            </p>
                                                        </>
                                                    )}
                                                </div>
                                                <div className="col-1 align-items-start">
                                                    <p className="m-0 w-100 text-center mt-2">/</p>
                                                </div>
                                                <div className="col-5 flex-column">
                                                    <Input
                                                        className="form-control form-control--input form-control--readonly result__price"
                                                        id="input-6"
                                                        name="sellingUnitPrice"
                                                        type="text"
                                                        placeholder={t('card_registration.placeholder.unit_price')}
                                                        readonly="readonly"
                                                        autocomplete="off"
                                                        disabled
                                                        onChange={(value) => handleChangeInput('sellingUnitPrice', value)}
                                                        value={sellingUnitPrice || ''}
                                                    />
                                                </div>
                                                <div className="col-1"></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 align-items-start">
                                            <div className="row w-100">
                                                <p className="col-12 offset-1 mb-2">{t('card_registration.price.including_tax.title')}</p>
                                                <div className="col-5 offset-1 flex-column">
                                                    <Input
                                                        className="form-control form-control--input form-control--readonly input__tax-price"
                                                        id="input-71"
                                                        name="discountedRequestSellingPriceTax"
                                                        placeholder="売価"
                                                        type="text"
                                                        readonly=""
                                                        autocomplete="off"
                                                        maxLength="6"
                                                        onChange={(value) => handleChangeInput('discountedRequestSellingPriceTax', value)}
                                                        onBlur={(value) =>
                                                            handleSellingPriceChange('discountedRequestSellingPriceTax', value)
                                                        }
                                                        value={discountedRequestSellingPriceTax || ''}
                                                        disabled={discountRate || taxIncludedType === 'excluded'}
                                                    />
                                                </div>
                                                <div className="col-1 align-items-start">
                                                    <p className="m-0 w-100 text-center mt-2">/</p>
                                                </div>
                                                <div className="col-5 flex-column">
                                                    <Input
                                                        className="form-control form-control--input form-control--readonly result__tax-price"
                                                        id="input-81"
                                                        name="sellingUnitPriceTax"
                                                        placeholder={t('card_registration.placeholder.unit_price')}
                                                        type="text"
                                                        readonly="readonly"
                                                        autocomplete="off"
                                                        disabled
                                                        onChange={(value) => handleChangeInput('sellingUnitPriceTax', value)}
                                                        value={sellingUnitPriceTax || ''}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                }
                {isFreshness && (
                    // 割引率適用
                    <div className="row okc-card__row">
                        <div className="col-lg-3 okc-result-form__title">
                            <p>{t('card_fresshness_registration.discount_rate_application')}</p>
                        </div>
                        <div className="col-lg-7 offset-lg-1 flex-column">
                            <div className="row no-gutters w-100 flex-xl-nowrap">
                                <div className="col-xl-6 align-items-start mb-2 mb-xl-0 d-flex flex-column">
                                    <div className="row no-gutters w-100 flex-xl-nowrap">
                                        <div className="col-5 flex-column js-okc-validate">
                                            <Input
                                                className={`form-control form-control--input ${isCheckDiscountRate ? 'form-control--error' : ''}`}
                                                data-target="discount-rate"
                                                value={discountRate || ''}
                                                id="input-7"
                                                type="text"
                                                autocomplete="off"
                                                maxLength="6"
                                                onChange={(value) => {
                                                    handleSellingPriceChange('discountRate', value);
                                                    handleChangeInput('discountRate', value);
                                                }}
                                                disabled={!discountRate && !!discountedRequestSellingPrice}
                                            />
                                            {isCheckDiscountRate && (
                                                <p className="okc-error mt-2 mb-0">
                                                    {t('message.error.discount.rate.has.not.been.entered')}
                                                </p>
                                            )}
                                        </div>
                                        <div className="col-2 align-items-start">
                                            <p className="m-0 w-100 text-center mt-2">
                                                {t('card_fresshness_registration.percent_discount')}
                                            </p>
                                        </div>
                                        <div className="col-5 flex-column">
                                            <Input
                                                className="form-control form-control--input form-control--readonly"
                                                data-target="discount-rate-selling-price"
                                                id="input-8"
                                                type="text"
                                                value={discountRate ? discountedRequestSellingPrice : ''}
                                                placeholder={t('card_fresshness_registration.placeholder.discount_rate')}
                                                readonly="readonly"
                                                autocomplete="off"
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="col-auto align-items-start">
                                            <p className="m-0 w-100 mt-2">&nbsp;{t('card_fresshness_registration.circle')}</p>
                                        </div>
                                        <div className="col-1"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {(isCompetitor || isInstruction) && (
                    // 販売方法・荷姿
                    <div className="row okc-card__row">
                        <div className="col-lg-3 okc-result-form__title">
                            <p>{t('card_registration.sales_conditions')}</p>
                        </div>
                        <div className="col-lg-7 offset-lg-1 flex-column">
                            <div className="row w-100 js-okc-validate">
                                <Choice
                                    id="packingCategory"
                                    column="col-sm-3"
                                    type="radio"
                                    data={packingCategories || []}
                                    onChange={(value) => handleChangeInput('packingCategory', value[0])}
                                    checked={packingCategory || ''}
                                    value={packingCategory || ''}
                                />
                            </div>
                            <p className="col-12 okc-error mt-2 mb-0 d-none">
                                {t('card_registration.placeholder.validate.sale_conditions')}
                            </p>
                        </div>
                        <div className="col-7 offset-4 mt-2">
                            <Input
                                className="form-control form-control--input packingCategoryOtherInput"
                                id="input-7"
                                type="text"
                                name="otherPackingCategory"
                                maxLength="60"
                                placeholder={t('card_registration.placeholder.other_input_fields')}
                                autocomplete="off"
                                onChange={(value) => handleChangeInput('otherPackingCategory', value)}
                                disabled={packingCategory !== 'OTHER'}
                            />
                        </div>
                    </div>
                )}
                {(isCompetitor || isInstruction) && (
                    // 特売種別・期間
                    <div className="row okc-card__row">
                        <div className="col-lg-3 okc-result-form__title">
                            <p>{t('card_registration.sale_type')}</p>
                        </div>
                        <div className="col-lg-7 offset-lg-1 flex-column">
                            <div className="row w-100 js-okc-validate">
                                <Choice
                                    id="saleType"
                                    column="col-sm-4"
                                    type="radio"
                                    data={saleTypes || []}
                                    onChange={(value) => handleChangeInput('saleType', value[0])}
                                    checked={saleType}
                                    value={saleType || ''}
                                />
                            </div>
                            <p className="col-12 okc-error mt-2 mb-0 d-none">
                                {t('card_registration.placeholder.validate.special_sale_type')}
                            </p>
                        </div>
                        <div className="col-7 offset-4 mt-2">
                            <Input
                                className="form-control form-control--input saleTypeOtherInput"
                                id="valueInput"
                                type="text"
                                maxLength="60"
                                placeholder={t('card_registration.placeholder.other_input_fields')}
                                onChange={(value) => handleChangeInput('otherSaleType', value)}
                                disabled={saleType !== 'OTHER'}
                            />
                        </div>
                    </div>
                )}
                {
                    // 開始日 ～ 終了日
                    <div className="row okc-card__row">
                        <div className="col-lg-3 okc-result-form__title">
                            <p>{t('card_registration.date')}</p>
                        </div>
                        <div className="col-5 col-lg-3 offset-lg-1 flex-column align-items-start js-okc-validate">
                            <ReactCalender
                                classNameInputDateTime={`js-datepicker salesStartDate
                                ${isCheckSalesStartDate && !salesStartDate ? 'form-control--error' : ''}`}
                                onChange={(value) => handleChangeInput('salesStartDate', value)}
                                value={salesStartDate || ''}
                                placeholder={t('card_registration.placeholder.start_date')}
                                minDate={minStartDate}
                                maxDate={maxStartDate}
                                isWeekend={saleType === 'WEEKEND'}
                            />
                            {isCheckSalesStartDate && !salesStartDate && (
                                <p className="okc-error mt-2 mb-0">{t('card_registration.placeholder.validate.start_date')}</p>
                            )}
                            {salesStartDate && isCheckSalesStartDate && salesStartDate < new Date() && (
                                <p className="okc-error-2 mt-2 mb-0">{t('card_registration.placeholder.invalid.start_date')}</p>
                            )}
                        </div>
                        <div className="col-2 col-lg-1">
                            <p className="m-0 w-100 text-center">～</p>
                        </div>
                        <div className="col-5 col-lg-3 flex-column align-items-start js-okc-validate">
                            <ReactCalender
                                classNameInputDateTime={`js-datepicker salesEndDate
                                ${isCheckSalesEndDate && !salesEndDate ? 'form-control--error' : ''}`}
                                onChange={(value) => handleChangeInput('salesEndDate', value)}
                                value={salesEndDate || ''}
                                placeholder={t('card_registration.placeholder.end_date')}
                                minDate={minEndDate}
                                maxDate={maxEndDate}
                                isWeekend={saleType === 'WEEKEND'}
                            />
                            {isCheckSalesEndDate && !salesEndDate && (
                                <p className="okc-error mt-2 mb-0">{t('card_registration.placeholder.validate.end_date')}</p>
                            )}
                        </div>
                    </div>
                }
                {isFreshness && (
                    // バイヤー報告
                    <div className="row okc-card__row">
                        <div className="col-lg-3 okc-result-form__title">
                            <p>{t('card_fresshness_registration.report_flg')}</p>
                        </div>
                        <div className="col-lg-7 offset-lg-1 flex-column pt-2">
                            <div className="row no-gutters w-100 js-okc-validate mb-3 flex-xl-nowrap">
                                <Choice
                                    id="reportFlag"
                                    className="form-check-input"
                                    column="col-sm-4"
                                    type="radio"
                                    data={REPORT_FLAG}
                                    onChange={(value) => handleChangeInput('reportFlag', value[0] === '1')}
                                    checked={[reportFlag ? '1' : '0']}
                                    value={reportFlag ? '1' : '0'}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {isFreshness && (
                    // 鮮度起因値下げ理由
                    <div className="row okc-card__row">
                        <div className="col-lg-3 okc-result-form__title">
                            <p>{t('card_fresshness_registration.freshness_reason')}</p>
                        </div>
                        <div className="col-lg-7 offset-lg-1 flex-column">
                            <div className="row no-gutters w-100 js-okc-validate mb-3 flex-xl-nowrap">
                                <Choice
                                    id="freshnessReasonType"
                                    className="form-check-input"
                                    column="col-sm-4"
                                    type="radio"
                                    data={FRESHNESS_REASON_TYPE}
                                    onChange={(value) => handleChangeInput('freshnessReasonType', value[0])}
                                    checked={[freshnessReasonType || '']}
                                    value={freshnessReasonType || ''}
                                />
                            </div>
                            <div className="row no-gutters w-100 flex-xl-nowrap js-okc-validate">
                                <div className="col-xl-12 align-items-start flex-column">
                                    <div className="form-textarea">
                                        <Input
                                            type="textarea"
                                            className="form-control form-control--input"
                                            data-target="freshnessReason"
                                            maxLength="100"
                                            placeholder={t('card_fresshness_registration.placeholder.freshness_reason')}
                                            onChange={(value) => handleChangeInput('freshnessReason', value)}
                                            value={freshnessReason || ''}
                                            disabled={freshnessReasonType === '賞味期限間近'}
                                        />
                                        <div className="form-textarea__divider"></div>
                                    </div>
                                    {freshnessReasonType === 'その他' && !freshnessReason && (
                                        <p className="okc-error mt-2 mb-0 freshnessReasonError">
                                            {t('card_fresshness_registration.validate.freshness_reason')}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {
                    // その他特記事項
                    <div className="row okc-card__row">
                        <div className="col-lg-3 okc-result-form__title">
                            <p>{t('card_registration.other_noteworthy_things')}</p>
                        </div>
                        <div className="col-lg-7 offset-lg-1">
                            <div className="form-textarea">
                                <Input
                                    type="textarea"
                                    className="form-control otherSpecialReports"
                                    maxLength="100"
                                    placeholder={t('card_registration.placeholder.other_note_input_fields')}
                                    onChange={(value) => handleChangeInput('otherSpecialReports', value)}
                                    value={otherSpecialReports || ''}
                                />
                                <div className="form-textarea__divider"></div>
                            </div>
                        </div>
                    </div>
                }
            </Card>
        </div>
    );
};

export default CardPriceSearch;
