import Modal from '../../common/Modal';
import Card from '../../common/Card';
import SelectSearch from '../../common/SelectSearch';
import { createCompetitorChainOptions, createCompetitorStoreOptions, createStoreOptions } from '../../../utils/helpers/option';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchBusinessFormats,
    fetchCompetitorChains,
    fetchCompetitorStores,
    fetchDistances,
    fetchFrequencyRanks,
    fetchStores,
    setLoader,
} from '../../../redux/actions/api';
import { CompetitorMasterControllerService, SurveyHistoryControllerService } from '../../../services';
import { setToastsError, setToastsSuccess } from '../../../redux/actions/common';
import apiErrorHandler from '../../../api/apiErrorHandler';
import { useLocation } from 'react-router-dom';
import { clearErrors } from '../../../redux/actions/error';
import Button from '../../common/Button';

const DEFAULT_STATE = {
    selectedDistance: '',
    selectedRank: '',
    notificationFlag: '',

    apiResponse: null,
    selectedModalCompetitorChainCode: '',
    selectedModalOkStoreCode: '',
    selectedModalStoreCode: '',

    deleteIndex: null,
    isShowConfirmDeleteModal: false,
    operation: '',
};

const DEFAULT_ROWS = [{ id: 1, storeCode: '', distance: '', rank: '', notificationFlag: false }];

const CompetitorCombinationMasterForStoreModal = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const { isShow, handleCloseModal } = props;

    const constants = useSelector((state) => state.constants);
    const { stores, competitorStores, competitorChains, distances, frequencyRanks } = constants;

    const [state, setState] = useState(DEFAULT_STATE);

    const [rows, setRows] = useState(DEFAULT_ROWS);

    const {
        apiResponse,
        selectedModalOkStoreCode,

        deleteIndex,
        isShowConfirmDeleteModal,
        operation,
    } = state;

    const operations = [
        { id: 'register', value: 'register', label: '登録' },
        { id: 'update', value: 'update', label: '更新' },
    ];

    useEffect(() => {
        dispatch(clearErrors());
        dispatch(fetchStores());
        dispatch(fetchCompetitorChains());
        dispatch(fetchCompetitorStores());
        dispatch(fetchDistances());
        dispatch(fetchFrequencyRanks());
        dispatch(fetchBusinessFormats());
    }, [dispatch, location]);

    const getCombinationCompetitorMaster = () => {
        dispatch(setLoader(true));
        dispatch(clearErrors());
        // オーケー店舗登録モーダルの競合店舗と店舗名を入れ替え
        CompetitorMasterControllerService.searchCompetitorCombinations(null, selectedModalOkStoreCode, null, null)
            .then((response) => {
                handleChangeState('apiResponse', response);
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        if (selectedModalOkStoreCode) {
            getCombinationCompetitorMaster();
        }
    }, [selectedModalOkStoreCode]);

    const handleChangeState = (field, value) => {
        setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleChangeApiResponse = (index, field, value) => {
        const newApiResponseData = apiResponse.data;
        newApiResponseData[index][field] = value;
        handleChangeState('apiResponse', {
            ...apiResponse,
            data: newApiResponseData,
        });
    };

    const handleSubmit = () => {
        dispatch(setLoader(true));
        dispatch(clearErrors());
        const updateData = apiResponse.data.map(({ id, distance, storeRank, deleteFlag, notificationFlag }) => ({
            id,
            distance,
            storeRank,
            deleteFlag,
            notificationFlag,
        }));

        const createData = rows.map(({ id, ...rest }) => ({
            ...rest,
            storeCode: selectedModalOkStoreCode,
        }));

        operation === 'update'
            ? CompetitorMasterControllerService.updateCompetitorCombinations({ combinationUpdateInfos: updateData })
                  .then(() => {
                      dispatch(setToastsSuccess('競合店舗紐づけを更新しました。'));
                      setState(DEFAULT_STATE);
                      setRows(DEFAULT_ROWS);
                      handleCloseModal();
                  })
                  .catch((error) => {
                      apiErrorHandler(dispatch, error, t('message.error.update'));
                  })
                  .finally(() => {
                      dispatch(setLoader(false));
                  })
            : CompetitorMasterControllerService.createCompetitorCombinations({ combinationCreateInfos: createData })
                  .then(() => {
                      dispatch(setToastsSuccess('競合店舗紐づけを登録しました。'));
                      setState(DEFAULT_STATE);
                      setRows(DEFAULT_ROWS);
                      handleCloseModal();
                  })
                  .catch((error) => {
                      apiErrorHandler(dispatch, error, t('message.error.update'));
                  })
                  .finally(() => {
                      dispatch(setLoader(false));
                  });
    };

    const toggleDeleteCheckbox = (index) => {
        handleChangeState('deleteIndex', index);
        handleChangeState('isShowConfirmDeleteModal', true);
    };

    const handleDelete = () => {
        const deleteFlag = apiResponse.data[deleteIndex].deleteFlag;
        handleChangeApiResponse(deleteIndex, 'deleteFlag', !deleteFlag);
        handleChangeState('isShowConfirmDeleteModal', false);
    };

    const handleResetAndCloseModal = () => {
        setState(DEFAULT_STATE);
        setRows(DEFAULT_ROWS);
        handleCloseModal();
    };

    const handleChangeRow = (id, field, value) => {
        setRows((prevRows) => prevRows.map((row) => (row.id === id ? { ...row, [field]: value } : row)));
    };

    const handleAddRow = () => {
        setRows((prevRows) => [
            ...prevRows,
            {
                id: prevRows.length ? prevRows[prevRows.length - 1].id + 1 : 1,
                storeCode: '',
                distance: '',
                rank: '',
                notificationFlag: false,
            },
        ]);
    };

    const handleRemoveRow = (id) => {
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));
    };

    return (
        <>
            <Modal
                size="xl"
                isShow={isShow}
                onClose={handleResetAndCloseModal}
                isShowIconClose="true"
                title={t('update_competitor_master.registration-modal.header')}
                btnList={[
                    {
                        name:
                            operation === 'update'
                                ? t('update_competitor_master.registration-modal.footer.primary-btn')
                                : t('update_competitor_master.update-modal.footer.primary-btn'),
                        className: 'btn--primary',
                        onClick: () => handleSubmit(),
                    },
                    {
                        name: t('update_competitor_master.registration-modal.footer.secondary-btn'),
                        className: 'btn--secondary',
                        onClick: () => handleResetAndCloseModal(),
                    },
                ]}
            >
                <div className="okc-card okc-result-form">
                    <Card type="modal_card">
                        <div className="row okc-card__row">
                            <div className="col-lg-3 okc-result-form__title">
                                <p>操作</p>
                            </div>
                            <div className="col-lg-8 offset-lg-1 d-flex">
                                {operations?.map((option, index) => (
                                    <div key={index} className={`form-check custom-radio col-xl-3 col-lg-4 col-md-5`}>
                                        <input
                                            className={`${option.label ? 'bases__margin--right4' : ''} form-check-input`}
                                            type="radio"
                                            value={option.value}
                                            name={option.id}
                                            id={option.id}
                                            checked={operation === option.value}
                                            onChange={() => handleChangeState('operation', option.value)}
                                        />
                                        <label className="form-check-label" htmlFor={option.id}>
                                            {option.label}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="row okc-card__row">
                            <div className="col-lg-3 okc-result-form__title">
                                <p>オーケー店舗名</p>
                            </div>
                            <div className="col-lg-8 offset-lg-1 d-flex">
                                <div className="row w-100">
                                    <div className="col-12 mb-2 js-switch-target" data-select2-id="select2-data-1421-wogk">
                                        <SelectSearch
                                            onChange={(option) => handleChangeState('selectedModalOkStoreCode', option.value)}
                                            options={createStoreOptions(stores)}
                                            value={selectedModalOkStoreCode}
                                            placeholder="オーケー店舗名を検索して選択してください"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {selectedModalOkStoreCode && (
                            <div className="competitor-store-list-wrapper">
                                <div className="competitor-store-header">
                                    <div className="row okc-card__row">
                                        <div className="col-lg-10 store-info">
                                            <div className="col-lg-2">
                                                {t('update_competitor_master.search_form.competitor_chain.text')}
                                            </div>
                                            <div className="col-lg-2">
                                                {t('update_competitor_master.search_form.competitor_store.text')}
                                            </div>
                                            <div className="col-lg-2">
                                                {t('update_competitor_master.registration-modal.body.store-list.distance')}
                                            </div>
                                            <div className="col-lg-2">
                                                {t('update_competitor_master.registration-modal.body.store-list.rank')}
                                            </div>
                                        </div>
                                        <div className="col-lg-1 flex-column">
                                            {t('update_competitor_master.registration-modal.body.store-list.notification_flag')}
                                        </div>
                                        <div className="col-lg-1 flex-column">
                                            {operation === 'update'
                                                ? t('update_competitor_master.registration-modal.body.store-list.delete_flag')
                                                : t('button.delete')}
                                        </div>
                                    </div>
                                </div>
                                <div className="competitor-store-list">
                                    {operation === 'update' &&
                                        apiResponse?.data.map((item, index) => (
                                            <div className="row okc-card__row">
                                                <div key={item.id} className="col-lg-10 store-info">
                                                    <div className="col-lg-2">{item.competitorChainName}</div>
                                                    <div className="col-lg-2">{item.competitorStoreName}</div>
                                                    <div className="col-lg-2">
                                                        <SelectSearch
                                                            onChange={(option) => {
                                                                handleChangeApiResponse(index, 'distance', option.value);
                                                            }}
                                                            options={distances}
                                                            value={item.distance}
                                                            placeholder={t(
                                                                'update_competitor_master.registration-modal.body.distance.placeholder',
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-lg-2">
                                                        <SelectSearch
                                                            onChange={(option) => {
                                                                handleChangeApiResponse(index, 'storeRank', option.value);
                                                            }}
                                                            options={frequencyRanks}
                                                            value={item.storeRank}
                                                            placeholder={t(
                                                                'update_competitor_master.registration-modal.body.rank.placeholder',
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-1 flex-column">
                                                    <div key={index} className={`form-check custom-checkbox`}>
                                                        <input
                                                            className="form-check-input"
                                                            onChange={() =>
                                                                handleChangeApiResponse(index, 'notificationFlag', !item.notificationFlag)
                                                            }
                                                            type="checkbox"
                                                            id={`notificationFlag-${item.id}`}
                                                            checked={item.notificationFlag}
                                                        />
                                                        <label className="form-check-label" htmlFor={`notificationFlag-${item.id}`}></label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1 flex-column">
                                                    <div key={index} className={`form-check custom-checkbox`}>
                                                        <input
                                                            className="form-check-input"
                                                            onChange={() => toggleDeleteCheckbox(index)}
                                                            type="checkbox"
                                                            id={`deleteFlag-${item.id}`}
                                                            checked={!item.deleteFlag}
                                                        />
                                                        <label className="form-check-label" htmlFor={`deleteFlag-${item.id}`}></label>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        )}
                        {operation === 'register' && (
                            <div className="row okc-card__row justify-content-space-evenly px-5">
                                {rows.map((row) => (
                                    <div key={row.id} className="d-flex align-items-center mb-3 store-info">
                                        <div className="col-lg-2 flex-column">
                                            <SelectSearch
                                                onChange={(option) =>
                                                    handleChangeRow(row.id, 'selectedModalCompetitorChainCode', option.value)
                                                }
                                                options={createCompetitorChainOptions(competitorChains)}
                                                value={row.selectedModalCompetitorChainCode}
                                                placeholder={t('price_request.competitor_name.placeholder')}
                                            />
                                        </div>
                                        <div className="col-lg-2 flex-column" style={{ marginLeft: '30px' }}>
                                            <SelectSearch
                                                onChange={(option) => handleChangeRow(row.id, 'competitorStoreCode', option.value)}
                                                options={createCompetitorStoreOptions(
                                                    competitorStores,
                                                    row.selectedModalCompetitorChainCode,
                                                )}
                                                value={row.competitorStoreCode}
                                                placeholder={t('price_request.competitor_store_name.placeholder')}
                                            />
                                        </div>
                                        <div className="col-lg-2 flex-column" style={{ marginLeft: '30px' }}>
                                            <SelectSearch
                                                onChange={(option) => handleChangeRow(row.id, 'distance', option.value)}
                                                options={distances}
                                                value={row.distance}
                                                placeholder={t('update_competitor_master.registration-modal.body.distance.placeholder')}
                                            />
                                        </div>
                                        <div className="col-lg-2 flex-column" style={{ marginLeft: '30px' }}>
                                            <SelectSearch
                                                onChange={(option) => handleChangeRow(row.id, 'rank', option.value)}
                                                options={frequencyRanks}
                                                value={row.rank}
                                                placeholder={t('update_competitor_master.registration-modal.body.rank.placeholder')}
                                            />
                                        </div>
                                        <div className="col-lg-1 flex-column">
                                            <div
                                                className={`form-check custom-checkbox`}
                                                style={{
                                                    marginLeft: '170px',
                                                    width: '38px',
                                                    height: '38px',
                                                }}
                                            >
                                                <input
                                                    className="form-check-input"
                                                    onChange={() => handleChangeRow(row.id, 'notificationFlag', !row.notificationFlag)}
                                                    type="checkbox"
                                                    id={`notificationFlag-${row.id}`}
                                                    value={row.notificationFlag}
                                                    checked={row.notificationFlag}
                                                />
                                                <label
                                                    className="form-check-label competitor-combination-master-modal"
                                                    htmlFor={`notificationFlag-${row.id}`}
                                                    style={{ width: '38px', height: '38px', padding: 0 }}
                                                ></label>
                                            </div>
                                        </div>
                                        {row.id !== 1 ? (
                                            <button
                                                className="btn btn--icon btn--secondary confirm-delete-modal"
                                                onClick={() => handleRemoveRow(row.id)}
                                                style={{ marginLeft: '110px' }}
                                            >
                                                <i className="icon-bin"></i>
                                                <span className="btn-text"></span>
                                            </button>
                                        ) : (
                                            <span style={{ marginLeft: '110px', width: '40px' }}></span>
                                        )}
                                    </div>
                                ))}
                                <div className="d-flex justify-content-center align-items-center fade-in mt-4">
                                    <Button className="btn btn--primary" onClick={handleAddRow}>
                                        <span className="btn-text">追加</span>
                                    </Button>
                                </div>
                            </div>
                        )}
                    </Card>
                </div>
            </Modal>

            <Modal
                size="sm"
                isShow={isShowConfirmDeleteModal}
                onClose={() => handleChangeState('isShowConfirmDeleteModal', false)}
                isShowIconClose="true"
                title={t('update_competitor_master.delete-modal.header')}
                btnList={[
                    {
                        name: t('update_competitor_master.delete-modal.primary_btn'),
                        className: 'btn--primary',
                        onClick: () => handleDelete(),
                    },
                    {
                        name: t('update_competitor_master.delete-modal.secondary_btn'),
                        className: 'btn--secondary',
                        onClick: () => handleChangeState('isShowConfirmDeleteModal', false),
                    },
                ]}
            >
                <p className="text-center">削除、または復元しますか？</p>
            </Modal>
        </>
    );
};

export default CompetitorCombinationMasterForStoreModal;
