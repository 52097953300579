/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApprovedChangeRequestSearchRequest } from '../models/ApprovedChangeRequestSearchRequest';
import type { ApprovedChangeRequestSearchResponse } from '../models/ApprovedChangeRequestSearchResponse';
import type { ChangeRequestApproveRequests } from '../models/ChangeRequestApproveRequests';
import type { ChangeRequestCompetitorCreateRequest } from '../models/ChangeRequestCompetitorCreateRequest';
import type { ChangeRequestCompetitorUpdateRequest } from '../models/ChangeRequestCompetitorUpdateRequest';
import type { ChangeRequestContinueCreateRequest } from '../models/ChangeRequestContinueCreateRequest';
import type { ChangeRequestDeleteRequest } from '../models/ChangeRequestDeleteRequest';
import type { ChangeRequestFreshnessCreateRequest } from '../models/ChangeRequestFreshnessCreateRequest';
import type { ChangeRequestFreshnessUpdateRequest } from '../models/ChangeRequestFreshnessUpdateRequest';
import type { ChangeRequestInstructionCreateRequest } from '../models/ChangeRequestInstructionCreateRequest';
import type { ChangeRequestInstructionUpdateRequest } from '../models/ChangeRequestInstructionUpdateRequest';
import type { ChangeRequestResponse } from '../models/ChangeRequestResponse';
import type { PriceValidityRequest } from '../models/PriceValidityRequest';
import type { PriceValidityResponse } from '../models/PriceValidityResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ChangeRequestControllerService {
    /**
     * @param changeRequestId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateChangeRequestInstruction(
        changeRequestId: number,
        requestBody: ChangeRequestInstructionUpdateRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/change-request/instruction/{changeRequestId}',
            path: {
                'changeRequestId': changeRequestId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param changeRequestId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateChangeRequestFreshness(
        changeRequestId: number,
        requestBody: ChangeRequestFreshnessUpdateRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/change-request/freshness/{changeRequestId}',
            path: {
                'changeRequestId': changeRequestId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param changeRequestId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateChangeRequestCompetitor(
        changeRequestId: number,
        requestBody: ChangeRequestCompetitorUpdateRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/change-request/competitor/{changeRequestId}',
            path: {
                'changeRequestId': changeRequestId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static approve(
        requestBody: ChangeRequestApproveRequests,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/change-request/approve',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param changeRequestIds
     * @returns any OK
     * @throws ApiError
     */
    public static apply(
        changeRequestIds: Array<number>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/change-request/apply',
            query: {
                'changeRequestIds': changeRequestIds,
            },
        });
    }
    /**
     * @param registrationMethod
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static createChangeRequestInstruction(
        registrationMethod: 'EXCEL' | 'FORM' | 'CONTINUATION' | 'HT_API' | 'CONVERSION',
        requestBody: Array<ChangeRequestInstructionCreateRequest>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/change-request/instruction/{registrationMethod}',
            path: {
                'registrationMethod': registrationMethod,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param registrationMethod
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static createChangeRequestFreshness(
        registrationMethod: 'EXCEL' | 'FORM' | 'CONTINUATION' | 'HT_API' | 'CONVERSION',
        requestBody: Array<ChangeRequestFreshnessCreateRequest>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/change-request/freshness/{registrationMethod}',
            path: {
                'registrationMethod': registrationMethod,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static createChangeRequestContinuation(
        requestBody: ChangeRequestContinueCreateRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/change-request/continuation',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param registrationMethod
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static createChangeRequestCompetitor(
        registrationMethod: 'EXCEL' | 'FORM' | 'CONTINUATION' | 'HT_API' | 'CONVERSION',
        requestBody: Array<ChangeRequestCompetitorCreateRequest>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/change-request/competitor/{registrationMethod}',
            path: {
                'registrationMethod': registrationMethod,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PriceValidityResponse OK
     * @throws ApiError
     */
    public static checkPriceValidity(
        requestBody: PriceValidityRequest,
    ): CancelablePromise<PriceValidityResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/change-request/check-price-validity',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns ApprovedChangeRequestSearchResponse OK
     * @throws ApiError
     */
    public static getChangeRequestsApproved(
        requestBody: ApprovedChangeRequestSearchRequest,
    ): CancelablePromise<ApprovedChangeRequestSearchResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/change-request/approved',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static deleteApprovedChangeRequest(
        requestBody: ChangeRequestDeleteRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/change-request/approved',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param changeRequestType
     * @param confirmStatus
     * @param requestedDate
     * @param page
     * @param limit
     * @param sortField
     * @param sortType
     * @returns ChangeRequestResponse OK
     * @throws ApiError
     */
    public static getChangeRequests(
        changeRequestType: 'COMPETITOR' | 'FRESHNESS' | 'INSTRUCTION',
        confirmStatus: 'REQUESTING' | 'NOT_CONFIRM' | 'CONFIRMED' | 'REMAND',
        requestedDate?: string,
        page: number = 1,
        limit: number = 20,
        sortField?: string,
        sortType?: 'ASC' | 'DESC',
    ): CancelablePromise<ChangeRequestResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/change-request',
            query: {
                'changeRequestType': changeRequestType,
                'confirmStatus': confirmStatus,
                'requestedDate': requestedDate,
                'page': page,
                'limit': limit,
                'sortField': sortField,
                'sortType': sortType,
            },
        });
    }
    /**
     * @param changeRequestIds
     * @returns any OK
     * @throws ApiError
     */
    public static deleteChangeRequest(
        changeRequestIds: Array<number>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/change-request',
            query: {
                'changeRequestIds': changeRequestIds,
            },
        });
    }
    /**
     * @param confirmStatus
     * @param competitorChainCode
     * @param competitorStoreCode
     * @param nearbyStoreCode
     * @param confirmRequestId
     * @param page
     * @param limit
     * @param sortField
     * @param sortType
     * @returns ChangeRequestResponse OK
     * @throws ApiError
     */
    public static getChangeRequestsNearbyStores(
        confirmStatus: 'REQUESTING' | 'NOT_CONFIRM' | 'CONFIRMED' | 'REMAND',
        competitorChainCode?: string,
        competitorStoreCode?: string,
        nearbyStoreCode?: string,
        confirmRequestId?: string,
        page: number = 1,
        limit: number = 20,
        sortField?: string,
        sortType?: 'ASC' | 'DESC',
    ): CancelablePromise<ChangeRequestResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/change-request/competitor/nearby-store',
            query: {
                'confirmStatus': confirmStatus,
                'competitorChainCode': competitorChainCode,
                'competitorStoreCode': competitorStoreCode,
                'nearbyStoreCode': nearbyStoreCode,
                'confirmRequestId': confirmRequestId,
                'page': page,
                'limit': limit,
                'sortField': sortField,
                'sortType': sortType,
            },
        });
    }
}
