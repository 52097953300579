import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './Layout';
import Login from './components/layout/Login';
import SelectMenu from './pages/select-menu';
import ChangeRequestPage from './pages/stores/change-request';
import SurveyHistory from './pages/stores/survey-history';
import UploadExcelPage from './pages/stores/upload-excel';
import ExportSummary from './pages/stores/export-summary';
import DemoTablePage from './pages/stores/demo-table';
import DepartmentSelectPage from './pages/stores/department-select';
import PriceSearchPage from './pages/stores/price-search';
import UpdateCompetitorMaster from './pages/headquarters/update-competitor-master';
import UserPage from './pages/ITheadquarters/users';
import ProhibitedItemPage from './pages/ITheadquarters/prohibited-item';
import ProhibitedCategoryRegisterPage from './pages/ITheadquarters/prohibited-category-register';
import ProhibitedCategoryPage from './pages/ITheadquarters/prohibited-category';
import ProhibitedItemImportPage from './pages/ITheadquarters/prohibited-item-import';
import NotificationPage from './pages/headquarters/notification';
import ExceptionItemPage from './pages/ITheadquarters/exception-item';
import ExceptionCategoryPage from './pages/ITheadquarters/exception-category';
import ExceptionItemImportPage from './pages/ITheadquarters/exception-item-import';
import ExceptionCategoryRegisterPage from './pages/ITheadquarters/exception-category-register';
import ExportChangeRequest from './pages/stores/export-change-request';
import CompetitorMaster from './pages/headquarters/competitor-master';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route path="/competitor/demo" element={<DemoTablePage />} />

                    <Route path="/survey-history" element={<SurveyHistory />} />
                    <Route path="/select-store" element={<DepartmentSelectPage />} />
                    <Route path="/price-search" element={<PriceSearchPage />} />
                    <Route path="/upload-excel" element={<UploadExcelPage />} />
                    <Route path="/change-request/:confirmStatus" element={<ChangeRequestPage />} />
                    <Route path="/export-summary" element={<ExportSummary />} />
                    <Route path="/export-change-request" element={<ExportChangeRequest />} />

                    <Route path="/competitor-master" element={<CompetitorMaster />} />
                    <Route path="/update-competitor-master" element={<UpdateCompetitorMaster />} />
                    <Route path="/users" element={<UserPage />} />
                    <Route path="/notification" element={<NotificationPage />} />

                    <Route path="/prohibited-item" element={<ProhibitedItemPage />} />
                    <Route path="/prohibited-item/import" element={<ProhibitedItemImportPage />} />
                    {/* <Route path="/prohibited-category" element={<ProhibitedCategoryPage />} /> */}
                    <Route path="/prohibited-category/register" element={<ProhibitedCategoryRegisterPage />} />

                    <Route path="/exception-item" element={<ExceptionItemPage />} />
                    <Route path="/exception-item/import" element={<ExceptionItemImportPage />} />
                    {/* <Route path="/exception-category" element={<ExceptionCategoryPage />} /> */}
                    <Route path="/exception-category/register" element={<ExceptionCategoryRegisterPage />} />
                </Route>
                <Route path="/login" element={<Login />} />
                <Route path="/select-menu" element={<SelectMenu />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
