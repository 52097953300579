import { forwardRef } from 'react';
import '../../assets/css/common/table.css';
import '../../assets/css/common/common.css';

const ViewTable = forwardRef((props, ref) => {
    const { heads, body, dataTable = [] } = props;

    const renderHeads = () => {
        return (
            <tr>
                {heads
                    ?.filter((item) => !!item)
                    .map((head, index) => {
                        if (Object.keys(head).length === 0) {
                            return <></>;
                        }

                        return (
                            <th key={index} colSpan={head.colSpan} className={`v-select-default ${head.className}`}>
                                <>{head?.title}</>
                            </th>
                        );
                    })}
            </tr>
        );
    };

    const renderRowValue = (itemColumn, itemRow) => {
        let rowValue = null;
        const fields = itemColumn?.field?.split('.') ?? [];

        for (const field of fields) {
            rowValue = !rowValue ? itemRow[field ?? ''] : rowValue[field];
        }

        // 競合対抗補填
        if (itemColumn?.field === 'competitorFlag') {
            rowValue = rowValue ? 'あり' : 'なし';
        }
        // PB・留型
        if (itemColumn?.field === 'nbpbkbn') {
            rowValue = rowValue === 1 || rowValue === 2 ? '〇' : '-';
        }

        if (rowValue) {
            if (itemColumn?.mappingData) {
                return itemColumn?.mappingData.find((item) => item.value === rowValue)?.label ?? rowValue;
            }
            return rowValue;
        } else {
            return '-';
        }
    };

    const renderRows = () => {
        return (
            <>
                {dataTable.length > 0 ? (
                    <>
                        {dataTable?.map((itemRow, indexRow) => (
                            <tr key={indexRow} className={`v-select-default`}>
                                {body?.columns
                                    ?.filter((item) => !!item)
                                    .map((itemColumn, indexColumn) => {
                                        if (Object.keys(itemColumn).length === 0) {
                                            return <td key={indexColumn}></td>;
                                        }
                                        return (
                                            <td key={indexColumn} className={`${itemColumn?.className ?? ''}`}>
                                                <span className={itemColumn?.isLink ? 'components__table-link' : ''}>
                                                    {renderRowValue(itemColumn, itemRow)}
                                                </span>
                                            </td>
                                        );
                                    })}
                            </tr>
                        ))}
                    </>
                ) : (
                    <>
                        <tr className="no-result">
                            <td colSpan={heads?.length || 1}>該当商品が見つかりません。</td>
                        </tr>
                    </>
                )}
            </>
        );
    };

    return (
        <div>
            <div className={`okc-table components__table-wrapper bases__padding--bottom20 `}>
                <div className="okc-table__wrapper table-responsive">
                    <table className="w-100">
                        <thead>{renderHeads()}</thead>
                        <tbody>{renderRows()}</tbody>
                    </table>
                </div>
            </div>
        </div>
    );
});

export default ViewTable;
