/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ChangeRequestInstructionUpdateRequest = {
    discountedRequestSellingPrice?: number;
    salesStartDate?: string;
    salesEndDate?: string;
    otherSpecialReports?: string;
    expandSalesFloor?: ChangeRequestInstructionUpdateRequest.expandSalesFloor;
    packingCategory?: ChangeRequestInstructionUpdateRequest.packingCategory;
    saleType?: ChangeRequestInstructionUpdateRequest.saleType;
};
export namespace ChangeRequestInstructionUpdateRequest {
    export enum expandSalesFloor {
        REGULAR = 'REGULAR',
        END = 'END',
        WAGON = 'WAGON',
        FLYER = 'FLYER',
    }
    export enum packingCategory {
        SINGLE_ITEM = 'SINGLE_ITEM',
        BALL = 'BALL',
        CASE = 'CASE',
        BUNDLE = 'BUNDLE',
        OTHER = 'OTHER',
    }
    export enum saleType {
        DAILY = 'DAILY',
        WEEKLY = 'WEEKLY',
        WEEKEND = 'WEEKEND',
        MONTHLY = 'MONTHLY',
        OTHER = 'OTHER',
    }
}

