export const COMPETITOR_MANAGER = [
    { href: '/survey-history', name: '競合店調査・登録', field: 'surveyHistory' },
    { href: '/upload-excel', name: 'Excel取り込み' },
    { href: '/change-request/requesting', name: '競合店売価登録申請', field: 'requesting' },
    { href: '/change-request/not_confirm', name: '競合店売価登録承認', field: 'notConfirm' },
    { href: '/change-request/remand', name: '競合店売価登録差戻編集', field: 'remand' },
    { href: '/change-request/confirmed', name: '承認済み競合店売価一覧' },
    { href: '/export-change-request', name: '店舗売変確認リスト出力' },
];

export const COMPETITOR_EDITOR = [
    { href: '/survey-history', name: '競合店調査・登録', field: 'surveyHistory' },
    { href: '/upload-excel', name: 'Excel取り込み' },
    { href: '/change-request/requesting', name: '競合店売価登録申請', field: 'requesting' },
    { href: '/change-request/remand', name: '競合店売価登録差戻編集', field: 'remand' },
    { href: '/change-request/confirmed', name: '承認済み競合店売価一覧' },
    { href: '/export-change-request', name: '店舗売変確認リスト出力' },
];

export const FRESHNESS_MANAGER = [
    { href: '/select-store', name: '鮮度起因値下げ登録' },
    { href: '/change-request/requesting', name: '鮮度起因値下げ登録申請', field: 'requesting' },
    { href: '/change-request/not_confirm', name: '鮮度起因値下げ登録承認', field: 'notConfirm' },
    { href: '/change-request/remand', name: '鮮度起因値下げ差戻・編集', field: 'remand' },
    { href: '/change-request/confirmed', name: '承認済み鮮度起因値下げ一覧' },
    { href: '/export-change-request', name: '店舗売変確認リスト出力' },
];

export const FRESHNESS_EDITOR = [
    { href: '/select-store', name: '鮮度起因値下げ登録' },
    { href: '/change-request/requesting', name: '鮮度起因値下げ登録申請', field: 'requesting' },
    { href: '/change-request/remand', name: '鮮度起因値下げ差戻・編集', field: 'remand' },
    { href: '/change-request/confirmed', name: '承認済み鮮度起因値下げ一覧' },
    { href: '/export-change-request', name: '店舗売変確認リスト出力' },
];

export const INSTRUCTION_MANAGER = [
    { href: '/select-store', name: '競合店売価登録' },
    { href: '/change-request/requesting', name: '競合店売価登録申請', field: 'requesting' },
    { href: '/change-request/not_confirm', name: '競合店売価登録承認', field: 'notConfirm' },
    { href: '/change-request/remand', name: '競合店売価登録差戻編集', field: 'remand' },
    { href: '/change-request/confirmed', name: '承認済み競合店売価一覧' },
];

export const INSTRUCTION_EDITOR = [
    { href: '/select-store', name: '競合店売価登録' },
    { href: '/change-request/requesting', name: '競合店売価登録申請', field: 'requesting' },
    { href: '/change-request/remand', name: '競合店売価登録差戻編集', field: 'remand' },
    { href: '/change-request/confirmed', name: '承認済み競合店売価一覧' },
];

export const STORE_COMPETITOR_ONLY = [{ href: '/export-summary', name: '競合店対抗入力実績表出力' }];

export const HEADQUARTER_ONLY = [
    { href: '/competitor-master', name: '競合チェーン・店舗一覧' },
    { href: '/update-competitor-master', name: '競合店組み合わせ一覧' },
    { href: '/notification', name: '通知先一覧・登録' },
];

export const IT_HEADQUARTER_ONLY = [
    { href: '/users', name: 'ユーザー一覧' },
    { href: '/competitor-master', name: '競合チェーン・店舗一覧' },
    { href: '/update-competitor-master', name: '競合店組み合わせ一覧' },
    { href: '/prohibited-item', name: '禁止商品一覧' },
    { href: '/exception-item', name: '例外商品一覧' },
    { href: '/notification', name: '通知先一覧・登録' },
];
