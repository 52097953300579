/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExportChangeRequestRequest } from '../models/ExportChangeRequestRequest';
import type { ExportSummaryRequest } from '../models/ExportSummaryRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ExportControllerService {
    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static validateExportSummary(
        requestBody: ExportSummaryRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/validate-export-summary',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static validateExportChangeRequest(
        requestBody: ExportChangeRequestRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/validate-export-change-request',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
