import {
    AUTHORITY,
    CHANGE_REQUEST_TYPE,
    ORGANIZATION,
    REQUESTER_NAME,
    SELECTED_COMPETITOR_STORE_CODE,
    SELECTED_DEPARTMENTS,
    SELECTED_REGISTRATION_METHOD,
    SELECTED_SOURCE,
    STORE_CODE,
} from '../actions/type';
import { AdminRequest, PriceSearchRequest } from '../../services';

const initialState = {
    storeCode: localStorage.getItem(STORE_CODE),
    organization: localStorage.getItem(ORGANIZATION),
    isStore: localStorage.getItem(ORGANIZATION) === AdminRequest.organization.STORE,
    isHeadquarter: localStorage.getItem(ORGANIZATION) === AdminRequest.organization.HEADQUARTERS,
    isItHeadquarter: localStorage.getItem(ORGANIZATION) === AdminRequest.organization.IT_HEADQUARTERS,
    authority: localStorage.getItem(AUTHORITY),
    isAdmin: localStorage.getItem(AUTHORITY) === AdminRequest.authority.ADMIN,
    isManager: localStorage.getItem(AUTHORITY) === AdminRequest.authority.MANAGER,
    isEditor: localStorage.getItem(AUTHORITY) === AdminRequest.authority.EDITOR,
    changeRequestType: localStorage.getItem(CHANGE_REQUEST_TYPE),
    isCompetitor: localStorage.getItem(CHANGE_REQUEST_TYPE) === PriceSearchRequest.changeRequestType.COMPETITOR,
    isFreshness: localStorage.getItem(CHANGE_REQUEST_TYPE) === PriceSearchRequest.changeRequestType.FRESHNESS,
    isInstruction: localStorage.getItem(CHANGE_REQUEST_TYPE) === PriceSearchRequest.changeRequestType.INSTRUCTION,
    registerName: localStorage.getItem(REQUESTER_NAME),
    selectedSource: localStorage.getItem(SELECTED_SOURCE),
    selectedRegistrationMethod: localStorage.getItem(SELECTED_REGISTRATION_METHOD),
    selectedDepartments: localStorage.getItem(SELECTED_DEPARTMENTS)?.split(',') ?? [],
    selectedCompetitorStoreId: localStorage.getItem(SELECTED_COMPETITOR_STORE_CODE),
};

const storageReducer = (state = initialState, action) => {
    switch (action.type) {
        case STORE_CODE:
            localStorage.setItem(STORE_CODE, action.data);
            return {
                ...state,
                storeCode: action.data,
            };
        case ORGANIZATION:
            localStorage.setItem(ORGANIZATION, action.data);
            return {
                ...state,
                organization: action.data,
                isStore: action.data === AdminRequest.organization.STORE,
                isHeadquarter: action.data === AdminRequest.organization.HEADQUARTERS,
                isItHeadquarter: action.data === AdminRequest.organization.IT_HEADQUARTERS,
            };
        case AUTHORITY:
            localStorage.setItem(AUTHORITY, action.data);
            return {
                ...state,
                authority: action.data,
                isAdmin: action.data === AdminRequest.authority.ADMIN,
                isManager: action.data === AdminRequest.authority.MANAGER,
                isEditor: action.data === AdminRequest.authority.EDITOR,
            };
        case CHANGE_REQUEST_TYPE:
            localStorage.setItem(CHANGE_REQUEST_TYPE, action.data);
            return {
                ...state,
                changeRequestType: action.data,
                isCompetitor: action.data === PriceSearchRequest.changeRequestType.COMPETITOR,
                isFreshness: action.data === PriceSearchRequest.changeRequestType.FRESHNESS,
                isInstruction: action.data === PriceSearchRequest.changeRequestType.INSTRUCTION,
            };
        case REQUESTER_NAME:
            localStorage.setItem(REQUESTER_NAME, action.data);
            return {
                ...state,
                requesterName: action.data,
            };
        case SELECTED_SOURCE:
            localStorage.setItem(SELECTED_SOURCE, action.data);
            return {
                ...state,
                selectedSource: action.data,
            };
        case SELECTED_REGISTRATION_METHOD:
            localStorage.setItem(SELECTED_REGISTRATION_METHOD, action.data);
            return {
                ...state,
                selectedRegistrationMethod: action.data,
            };
        case SELECTED_DEPARTMENTS:
            localStorage.setItem(SELECTED_DEPARTMENTS, action.data.join(','));
            return {
                ...state,
                selectedDepartments: action.data,
            };
        case SELECTED_COMPETITOR_STORE_CODE:
            localStorage.setItem(SELECTED_COMPETITOR_STORE_CODE, action.data);
            return {
                ...state,
                selectedCompetitorStoreCode: action.data,
            };
        default:
            return state;
    }
};

const resetStorage = () => {
    localStorage.clear();
};

export { storageReducer, resetStorage };
