import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../assets/css/pages/stores/change-request-approved.css';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SelectSearch from '../../components/common/SelectSearch';
import Input from '../../components/common/Input';
import Label from '../../components/common/Label';
import Card from '../../components/common/Card';
import Pagination from '../../components/common/Pagination';
import EditTable from '../../components/tables/EditTable';
import Button from '../../components/common/Button';
import Modal from '../../components/common/Modal';
import Choice from '../../components/common/Choice';
import { clearErrors } from '../../redux/actions/error';
import { fetchBusinessFormats, fetchCompetitorChains, fetchCompetitorStores, setLoader } from '../../redux/actions/api';
import { CompetitorMasterControllerService } from '../../services';
import apiErrorHandler from '../../api/apiErrorHandler';
import { setDeleteConfirm } from '../../redux/actions/modal';
import { setToastsSuccess } from '../../redux/actions/common';
import { getTableCompetitorChain, getTableCompetitorStore } from '../../components/tables/table';
import { createCompetitorChainOptions } from '../../utils/helpers/option';
import CompetitorChainStoreModal from '../../components/layout/headquarters/CompetitorChainStoreModal';
import CompetitorStoreMasterModal from '../../components/layout/headquarters/CompetitorStoreMasterModal';

const CompetitorMaster = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const location = useLocation();

    const sort = useSelector((state) => state.sort);

    const constants = useSelector((state) => state.constants);
    const { competitorChains, competitorStores, businessFormats } = constants;

    const [state, setState] = useState({
        searchType: 'CHAIN',
        searchBusinessFormat: '',
        searchCompetitorChainCode: '',
        searchCompetitorStoreCode: '',
        searchCompetitorChainName: '',
        searchCompetitorStoreName: '',

        competitorChainCode: '',
        competitorStoreCode: '',
        competitorStoreNames: '',
        competitorChainName: '',
        selectedBusinessFormat: 'GMS_SM',

        isEditShowModal: false,
        isDeleteShowModal: false,
        isShowCompetitorChainStoreModal: false,
        isShowCompetitorStoreMasterModal: false,

        page: 1,
        limit: 20,
        apiResponse: null,
    });

    const {
        searchType,
        searchBusinessFormat,
        searchCompetitorChainCode,
        searchCompetitorStoreCode,
        searchCompetitorChainName,
        searchCompetitorStoreName,

        competitorChainCode,
        competitorStoreCode,
        competitorStoreName,
        competitorChainName,
        selectedBusinessFormat,

        isEditShowModal,
        isDeleteShowModal,
        isShowCompetitorChainStoreModal,
        isShowCompetitorStoreMasterModal,

        page,
        limit,
        apiResponse,
    } = state;

    useEffect(() => {
        dispatch(clearErrors());
        dispatch(fetchCompetitorChains());
        dispatch(fetchCompetitorStores());
        dispatch(fetchBusinessFormats());
    }, [dispatch, location]);

    useEffect(() => {
        getSearch();
    }, [dispatch, page, sort]);

    const handleEditClick = (index, value) => {
        handleChangeState('selectedBusinessFormat', value.businessFormat);
        handleChangeState('competitorChainCode', value.chainCode);
        handleChangeState('competitorChainName', value.chainName);
        handleChangeState('competitorStoreName', value.competitorStoreName);
        handleChangeState('competitorStoreCode', value.competitorCode);
        handleChangeState('isEditShowModal', true);
    };

    const handleDeleteClick = (index, value) => {
        handleChangeState('competitorChainCode', value.chainCode);
        handleChangeState('competitorStoreCode', value.competitorCode);
        handleChangeState('competitorStoreName', value.competitorStoreName);
        handleChangeState('isDeleteShowModal', true);
    };

    const table = getTableCompetitorChain(t, handleEditClick, handleDeleteClick);
    const storeTable = getTableCompetitorStore(t, handleEditClick, handleDeleteClick);

    const handleChangeState = (field, value) => {
        setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleCloseModal = () => {
        setState((prevState) => ({
            ...prevState,
            isEditShowModal: false,
            isDeleteShowModal: false,
        }));
    };

    const handlePageClick = (event) => {
        handleChangeState('page', event.selected + 1);
    };

    const handleClear = () => {
        setState((prevState) => ({
            ...prevState,
            searchCompetitorChainCode: '',
            searchCompetitorStoreCode: '',
            searchBusinessFormat: '',
            searchCompetitorChainName: '',
            searchCompetitorStoreName: '',
        }));
    };

    const getSearch = () => {
        dispatch(setLoader(true));
        dispatch(clearErrors());
        const search =
            searchType == 'CHAIN'
                ? table.heads.find((head) => head.field === sort.sortField)?.field
                : storeTable.heads.find((head) => head.field === sort.sortField)?.field;

        // 一覧用のAPI
        // 現在の検索要素はチェーンと店舗のコードのみ
        searchType == 'CHAIN'
            ? CompetitorMasterControllerService.searchCompetitorChains(
                  searchCompetitorChainCode ? searchCompetitorChainCode : null,
                  searchCompetitorChainName ? searchCompetitorChainName : null,
                  searchBusinessFormat,
                  page,
                  limit,
                  search,
                  sort.sortType,
              )
                  .then((response) => {
                      handleChangeState('apiResponse', response);
                  })
                  .catch((error) => {
                      apiErrorHandler(dispatch, error);
                  })
                  .finally(() => {
                      dispatch(setLoader(false));
                  })
            : CompetitorMasterControllerService.searchCompetitorStores(
                  searchCompetitorChainCode ? searchCompetitorChainCode : null,
                  searchCompetitorStoreCode ? searchCompetitorStoreCode : null,
                  searchCompetitorStoreName ? searchCompetitorStoreName : null,
                  page,
                  limit,
                  search,
                  sort.sortType,
              )
                  .then((response) => {
                      handleChangeState('apiResponse', response);
                  })
                  .catch((error) => {
                      apiErrorHandler(dispatch, error);
                  })
                  .finally(() => {
                      dispatch(setLoader(false));
                  });
    };

    const handleSubmitModalEdit = () => {
        dispatch(setLoader(true));
        dispatch(clearErrors());

        const requestbody =
            searchType == 'CHAIN'
                ? {
                      chainName: competitorChainName,
                      businessFormat: selectedBusinessFormat,
                      deleteFlag: false,
                  }
                : {
                      chainCode: competitorChainCode,
                      competitorStoreName: competitorStoreName,
                      deleteFlag: false,
                  };

        // 編集処理
        const request =
            searchType == 'CHAIN'
                ? CompetitorMasterControllerService.updateCompetitorChain(competitorChainCode, requestbody)
                : CompetitorMasterControllerService.updateCompetitorStore(competitorStoreCode, requestbody);
        request
            .then(() => {
                dispatch(setDeleteConfirm(false));
                setState((prevState) => ({
                    ...prevState,
                    isEditShowModal: false,
                }));
                dispatch(setToastsSuccess('編集が完了しました。'));
                getSearch();
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error, t('message.error.update'));
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleSubmitModalDelete = () => {
        dispatch(setLoader(true));
        dispatch(clearErrors());

        const requestbody =
            searchType == 'CHAIN'
                ? {
                      chainName: competitorChainName,
                      businessFormat: selectedBusinessFormat,
                      deleteFlag: true,
                  }
                : {
                      chainCode: competitorChainCode,
                      competitorStoreName: competitorStoreName,
                      deleteFlag: true,
                  };

        // 削除処理
        const request =
            searchType == 'CHAIN'
                ? CompetitorMasterControllerService.updateCompetitorChain(competitorChainCode, requestbody)
                : CompetitorMasterControllerService.updateCompetitorStore(competitorStoreCode, requestbody);
        request
            .then(() => {
                dispatch(setDeleteConfirm(false));
                setState((prevState) => ({
                    ...prevState,
                    isDeleteShowModal: false,
                }));
                dispatch(setToastsSuccess('削除が完了しました。'));
                getSearch();
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error, t('message.error.delete'));
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleChangeType = () => {
        setState((prevState) => ({
            ...prevState,
            page: 1,
            apiResponse: null,
        }));
    };

    return (
        <>
            <div className="okc-inner">
                <Card handleSubmit={getSearch} handleClear={handleClear}>
                    <>
                        <Label
                            type="column"
                            className="col-lg-2 mb-2 mb-lg-4 form-check-label"
                            text={t('prohibited_product_list.search_form.prohibited_search_type.label')}
                        />
                        <div className="col-lg-4 mb-4" id="selling-price-type">
                            <div className="row no-gutters w-100 js-okc-validate">
                                <Choice
                                    type="radio"
                                    column="col-sm-4"
                                    id="searchType"
                                    onChange={(value) => {
                                        handleChangeType(value[0]);
                                        handleChangeState('searchType', value[0]);
                                        handleClear();
                                    }}
                                    data={[
                                        {
                                            id: 'chain',
                                            value: 'CHAIN',
                                            label: t('export_summary.competitive_chain.title'),
                                        },
                                        {
                                            id: 'store',
                                            value: 'STORE',
                                            label: t('export_summary.competitive_store.title'),
                                        },
                                    ]}
                                    value={searchType}
                                    checked={searchType}
                                />
                            </div>
                        </div>
                        <Label type="column" className="col-lg-2 pl-lg-3 mb-2 mb-lg-4" />
                        <div className="col-lg-4 pr-lg-3 mb-4"></div>
                    </>
                    {searchType == 'CHAIN' && (
                        <>
                            <Label
                                type="column"
                                text={t('update_competitor_master.search_form.competitor_chain.text')}
                                className="col-lg-2 mb-2 mb-lg-4 v-base_height--40"
                            />
                            <div className="col-lg-4 pr-lg-3 mb-4">
                                <Input
                                    type="text"
                                    placeholder="競合チェーン名を入力してください"
                                    value={searchCompetitorChainName}
                                    onChange={(value) => handleChangeState('searchCompetitorChainName', value)}
                                />
                            </div>
                            <Label
                                type="column"
                                text={t('competitor_master.search_form.competitor_chain_code.text')}
                                className="col-lg-2 pl-lg-3 mb-2 mb-lg-4"
                            />
                            <div className="col-lg-4 pr-lg-3 mb-4">
                                <Input
                                    type="text"
                                    placeholder="競合チェーンコードを入力してください"
                                    value={searchCompetitorChainCode}
                                    onChange={(value) => handleChangeState('searchCompetitorChainCode', value)}
                                />
                            </div>
                            <Label
                                type="column"
                                text={t('update_competitor_master.update-modal.form.business_format.label')}
                                className="col-lg-2 mb-2 mb-lg-4 form-check-label"
                            />
                            <div className="col-lg-4 pr-lg-3 mb-4">
                                <div className="row no-gutters w-100 js-okc-validate product-category">
                                    <Choice
                                        id="categories"
                                        column="col-lg-4"
                                        type="radio"
                                        data={businessFormats}
                                        onChange={(target) => handleChangeState('searchBusinessFormat', target[0])}
                                        checked={[searchBusinessFormat]}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    {searchType == 'STORE' && (
                        <>
                            <Label
                                type="column"
                                text={t('update_competitor_master.search_form.competitor_chain.text')}
                                className="col-lg-2 mb-2 mb-lg-4 v-base_height--40"
                            />
                            <div className="col-lg-4 pr-lg-3 mb-4">
                                <SelectSearch
                                    onChange={(option) => handleChangeState('searchCompetitorChainCode', option.value)}
                                    options={createCompetitorChainOptions(competitorChains)}
                                    value={searchCompetitorChainCode}
                                    placeholder={t('price_request.competitor_name.placeholder')}
                                />
                            </div>
                            <Label
                                type="column"
                                text={t('update_competitor_master.search_form.competitor_store.text')}
                                className="col-lg-2 pl-lg-3 mb-2 mb-lg-4"
                            />
                            <div className="col-lg-4 pr-lg-3 mb-4">
                                <Input
                                    type="text"
                                    placeholder="競合店舗名を入力してください"
                                    value={searchCompetitorStoreName}
                                    onChange={(value) => handleChangeState('searchCompetitorStoreName', value)}
                                />
                            </div>
                            <Label
                                type="column"
                                text={t('competitor_master.search_form.competitor_store_code.text')}
                                className="col-lg-2 mb-2 mb-lg-4 v-base_height--40"
                            />
                            <div className="col-lg-4 pr-lg-3 mb-4">
                                <Input
                                    type="text"
                                    placeholder="競合店舗コードを入力してください"
                                    value={searchCompetitorStoreCode}
                                    onChange={(value) => handleChangeState('searchCompetitorStoreCode', value)}
                                />
                            </div>
                        </>
                    )}
                </Card>

                {searchType == 'CHAIN' && (
                    <>
                        <div className="d-flex justify-content-between align-items-center fade-in">
                            <div className="d-flex">
                                <Button
                                    className="btn btn--primary"
                                    onClick={() => handleChangeState('isShowCompetitorChainStoreModal', true)}
                                >
                                    <span className="btn-text">{t('update_competitor_master.button.competitor_chain_masters')}</span>
                                </Button>
                                <Button
                                    className="btn btn--primary ml-2"
                                    onClick={() => handleChangeState('isShowCompetitorStoreMasterModal', true)}
                                >
                                    <span className="btn-text">{t('update_competitor_master.button.competitor_store_masters')}</span>
                                </Button>
                            </div>
                            <div className="ml-auto">
                                <Pagination pageCount={apiResponse?.totalPage} onPageChange={handlePageClick} />
                            </div>
                        </div>
                        <EditTable
                            isHeightTr
                            heads={table.heads}
                            body={table.body}
                            dataTable={apiResponse?.data}
                            dataText={'競合チェーンが見つかりません。'}
                        />
                    </>
                )}
                {searchType == 'STORE' && (
                    <>
                        <div className="d-flex justify-content-between align-items-center fade-in">
                            <div className="d-flex">
                                <Button
                                    className="btn btn--primary"
                                    onClick={() => handleChangeState('isShowCompetitorChainStoreModal', true)}
                                >
                                    <span className="btn-text">{t('update_competitor_master.button.competitor_chain_masters')}</span>
                                </Button>
                                <Button
                                    className="btn btn--primary ml-2"
                                    onClick={() => handleChangeState('isShowCompetitorStoreMasterModal', true)}
                                >
                                    <span className="btn-text">{t('update_competitor_master.button.competitor_store_masters')}</span>
                                </Button>
                            </div>
                            <div className="ml-auto">
                                <Pagination pageCount={apiResponse?.totalPage} onPageChange={handlePageClick} />
                            </div>
                        </div>
                        <EditTable
                            isHeightTr
                            heads={storeTable.heads}
                            body={storeTable.body}
                            dataTable={apiResponse?.data}
                            dataText={'競合店舗が見つかりません。'}
                        />
                    </>
                )}
            </div>
            <CompetitorChainStoreModal
                isShow={isShowCompetitorChainStoreModal}
                handleCloseModal={() => handleChangeState('isShowCompetitorChainStoreModal', false)}
            />
            <CompetitorStoreMasterModal
                isShow={isShowCompetitorStoreMasterModal}
                handleCloseModal={() => handleChangeState('isShowCompetitorStoreMasterModal', false)}
            />
            <Modal
                size="lg"
                type="please_select_checkbox"
                isShow={isDeleteShowModal}
                onClose={handleCloseModal}
                isShowIconClose="true"
                title={searchType == 'CHAIN' ? '選択した競合チェーンを削除します。' : '選択した競合店舗を削除します。'}
                btnList={[
                    {
                        name: t('button.OK'),
                        className: 'btn--primary',
                        onClick: () => handleSubmitModalDelete(),
                        disabled: false,
                    },
                    {
                        name: t('button.cancel'),
                        className: 'btn--secondary',
                        onClick: () => handleCloseModal(),
                        disabled: false,
                    },
                ]}
                children={
                    searchType == 'CHAIN' ? (
                        <>
                            <p className="text-center">選択した競合チェーンを削除します。</p>
                            <p className="text-center">選択した競合チェーンに紐づく競合店舗も削除されます。</p>
                            <p className="text-center">
                                削除された競合店舗に紐づくオーケー店舗の画面に対象の競合店舗が表示されなくなります。
                            </p>
                        </>
                    ) : (
                        <>
                            <p className="text-center">選択した競合店舗を削除します。</p>
                            <p className="text-center">
                                削除された競合店舗に紐づくオーケー店舗の画面に対象の競合店舗が表示されなくなります。
                            </p>
                        </>
                    )
                }
            />
            <Modal
                type="please_select_checkbox"
                isShow={isEditShowModal}
                onClose={handleCloseModal}
                isShowIconClose="true"
                title={searchType == 'CHAIN' ? '競合チェーン編集' : '競合店舗編集'}
                btnList={[
                    {
                        name: t('button.update'),
                        className: 'btn--primary',
                        onClick: () => handleSubmitModalEdit(),
                        disabled: false,
                    },
                    {
                        name: t('button.cancel'),
                        className: 'btn--secondary',
                        onClick: () => handleCloseModal(),
                        disabled: false,
                    },
                ]}
            >
                <div className="okc-card okc-result-form">
                    {searchType == 'CHAIN' && (
                        <>
                            <div className="row okc-card__row">
                                <div className="col-lg-3 okc-result-form__title">
                                    <p>競合チェーン名</p>
                                </div>
                                <div className="col-lg-8 offset-lg-1 d-flex">
                                    <div className="row w-100">
                                        <div className="col-12 mb-2 js-switch-target" data-select2-id="select2-data-1421-wogk">
                                            <Input
                                                type="text"
                                                placeholder="競合チェーン名を入力"
                                                value={competitorChainName}
                                                onChange={(value) => handleChangeState('competitorChainName', value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row okc-card__row">
                                <div className="col-lg-3 okc-result-form__title">
                                    <p>{t('update_competitor_master.update-modal.form.business_format.label')}</p>
                                </div>
                                <div className="col-lg-8 offset-lg-1 flex-column">
                                    <div className="row no-gutters w-100 js-okc-validate">
                                        {businessFormats?.map((option, index) => (
                                            <div
                                                key={index}
                                                className={`form-check custom-radio col-xl- col-lg-3 col-md-5`}
                                                style={{ minWidth: 0 }}
                                            >
                                                <input
                                                    className={`${option.label ? 'bases__margin--right4' : ''} form-check-input`}
                                                    type="radio"
                                                    value={option.value}
                                                    name={option.id}
                                                    id={option.id}
                                                    checked={selectedBusinessFormat == option.value}
                                                    onChange={() => handleChangeState('selectedBusinessFormat', option.value)}
                                                />
                                                <label className="form-check-label" htmlFor={option.id}>
                                                    {option.label}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {searchType == 'STORE' && (
                        <Card type="modal_card">
                            <div className="row okc-card__row">
                                <div className="col-lg-3 okc-result-form__title">
                                    <p>競合店舗名</p>
                                </div>
                                <div className="col-lg-8 offset-lg-1 d-flex">
                                    <div className="row w-100">
                                        <div className="col-12 mb-2 js-switch-target" data-select2-id="select2-data-1421-wogk">
                                            <Input
                                                type="text"
                                                placeholder="競合店舗名を入力"
                                                value={competitorStoreName}
                                                onChange={(value) => handleChangeState('competitorStoreName', value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    )}
                </div>
            </Modal>
        </>
    );
};

export default CompetitorMaster;
