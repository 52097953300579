/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompetitorChainCreateRequest } from '../models/CompetitorChainCreateRequest';
import type { CompetitorChainResponse } from '../models/CompetitorChainResponse';
import type { CompetitorChainSearchResponse } from '../models/CompetitorChainSearchResponse';
import type { CompetitorChainUpdateRequest } from '../models/CompetitorChainUpdateRequest';
import type { CompetitorCombinationCreateRequest } from '../models/CompetitorCombinationCreateRequest';
import type { CompetitorCombinationSearchResponse } from '../models/CompetitorCombinationSearchResponse';
import type { CompetitorCombinationUpdateRequest } from '../models/CompetitorCombinationUpdateRequest';
import type { CompetitorStoreCreateRequest } from '../models/CompetitorStoreCreateRequest';
import type { CompetitorStoreResponse } from '../models/CompetitorStoreResponse';
import type { CompetitorStoreSearchResponse } from '../models/CompetitorStoreSearchResponse';
import type { CompetitorStoreUpdateRequest } from '../models/CompetitorStoreUpdateRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CompetitorMasterControllerService {
    /**
     * @param competitorCode
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateCompetitorStore(
        competitorCode: string,
        requestBody: CompetitorStoreUpdateRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/competitor/stores/{competitorCode}',
            path: {
                'competitorCode': competitorCode,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param competitorStoreMasterId
     * @param storeRank
     * @returns any OK
     * @throws ApiError
     */
    public static updateStoreRank(
        competitorStoreMasterId: number,
        storeRank: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/competitor/store-rank/{competitorStoreMasterId}',
            path: {
                'competitorStoreMasterId': competitorStoreMasterId,
            },
            query: {
                'storeRank': storeRank,
            },
        });
    }
    /**
     * @param registrationDate
     * @param storeCode
     * @param competitorChainCode
     * @param competitorStoreCode
     * @param page
     * @param limit
     * @param sortField
     * @param sortType
     * @returns CompetitorCombinationSearchResponse OK
     * @throws ApiError
     */
    public static searchCompetitorCombinations(
        registrationDate?: string,
        storeCode?: string,
        competitorChainCode?: string,
        competitorStoreCode?: string,
        page: number = 1,
        limit: number = 20,
        sortField?: string,
        sortType?: 'ASC' | 'DESC',
    ): CancelablePromise<CompetitorCombinationSearchResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/competitor/combinations',
            query: {
                'registrationDate': registrationDate,
                'storeCode': storeCode,
                'competitorChainCode': competitorChainCode,
                'competitorStoreCode': competitorStoreCode,
                'page': page,
                'limit': limit,
                'sortField': sortField,
                'sortType': sortType,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateCompetitorCombinations(
        requestBody: CompetitorCombinationUpdateRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/competitor/combinations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static createCompetitorCombinations(
        requestBody: CompetitorCombinationCreateRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/competitor/combinations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param chainCode
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateCompetitorChain(
        chainCode: string,
        requestBody: CompetitorChainUpdateRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/competitor/chain/{chainCode}',
            path: {
                'chainCode': chainCode,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns CompetitorStoreResponse OK
     * @throws ApiError
     */
    public static getCompetitorStores(): CancelablePromise<Array<CompetitorStoreResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/competitor/stores',
        });
    }
    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static createCompetitorStores(
        requestBody: CompetitorStoreCreateRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/competitor/stores',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns CompetitorChainResponse OK
     * @throws ApiError
     */
    public static getCompetitorChains(): CancelablePromise<Array<CompetitorChainResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/competitor/chains',
        });
    }
    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static createCompetitorChains(
        requestBody: CompetitorChainCreateRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/competitor/chains',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param chainCode
     * @param competitorCode
     * @param competitorStoreName
     * @param page
     * @param limit
     * @param sortField
     * @param sortType
     * @returns CompetitorStoreSearchResponse OK
     * @throws ApiError
     */
    public static searchCompetitorStores(
        chainCode?: string,
        competitorCode?: string,
        competitorStoreName?: string,
        page: number = 1,
        limit: number = 20,
        sortField?: string,
        sortType?: 'ASC' | 'DESC',
    ): CancelablePromise<CompetitorStoreSearchResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/competitor/stores/search',
            query: {
                'chainCode': chainCode,
                'competitorCode': competitorCode,
                'competitorStoreName': competitorStoreName,
                'page': page,
                'limit': limit,
                'sortField': sortField,
                'sortType': sortType,
            },
        });
    }
    /**
     * @param chainCode
     * @param chainName
     * @param businessFormat
     * @param page
     * @param limit
     * @param sortField
     * @param sortType
     * @returns CompetitorChainSearchResponse OK
     * @throws ApiError
     */
    public static searchCompetitorChains(
        chainCode?: string,
        chainName?: string,
        businessFormat?: 'GMS_SM' | 'SM_LOCAL' | 'DISCOUNT' | 'DRAG' | 'HOME_CENTER' | 'SPECIALTY_SHOP' | 'OTHER',
        page: number = 1,
        limit: number = 20,
        sortField?: string,
        sortType?: 'ASC' | 'DESC',
    ): CancelablePromise<CompetitorChainSearchResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/competitor/chains/search',
            query: {
                'chainCode': chainCode,
                'chainName': chainName,
                'businessFormat': businessFormat,
                'page': page,
                'limit': limit,
                'sortField': sortField,
                'sortType': sortType,
            },
        });
    }
}
