/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ChangeRequestCompetitorCreateRequest = {
    id?: number;
    jan?: string;
    itemName?: string;
    imageUrl?: string;
    departmentCode?: number;
    lineCode?: number;
    classCode?: number;
    subclassCode?: number;
    lowerLimit?: number;
    itemCategoryName?: string;
    listPrice?: number;
    capacityOfUnit?: number;
    quantityOfUnit?: number;
    unit?: string;
    lowestSellingPrice?: number;
    discountedLowestSellingPrice?: number;
    lowestSellingUnitPrice?: number;
    lowestPriceTypeName?: string;
    effectiveEndDate?: string;
    competitorName?: string;
    edlpSellingPrice?: number;
    discountedEdlpSellingPrice?: number;
    edlpSellingUnitPrice?: number;
    competitorFlag?: boolean;
    nbpbkbn?: number;
    packingCategory?: ChangeRequestCompetitorCreateRequest.packingCategory;
    saratokuSellingPrice?: number;
    chomeSellingPrice?: number;
    taxRate?: number;
    discountedCategory: string;
    lowestPriceType?: ChangeRequestCompetitorCreateRequest.lowestPriceType;
    stopPurchaseFlag?: boolean;
    requesterName?: string;
    discountedRequestSellingPrice?: number;
    salesStartDate: string;
    salesEndDate: string;
    otherSpecialReports?: string;
    source?: ChangeRequestCompetitorCreateRequest.source;
    competitorStoreCode?: string;
    expandSalesFloor?: ChangeRequestCompetitorCreateRequest.expandSalesFloor;
    saleType?: ChangeRequestCompetitorCreateRequest.saleType;
    otherPackingCategory?: string;
    otherSaleType?: string;
    prohibited?: boolean;
};
export namespace ChangeRequestCompetitorCreateRequest {
    export enum packingCategory {
        SINGLE_ITEM = 'SINGLE_ITEM',
        BALL = 'BALL',
        CASE = 'CASE',
        BUNDLE = 'BUNDLE',
        OTHER = 'OTHER',
    }
    export enum lowestPriceType {
        EDLP = 'EDLP',
        SARATOKU = 'SARATOKU',
        CHOME = 'CHOME',
        COMPETITOR = 'COMPETITOR',
        FRESHNESS = 'FRESHNESS',
        POS_CHANGED = 'POS_CHANGED',
        ORIGINAL_SELLING_PRICE_CHANGE = 'ORIGINAL_SELLING_PRICE_CHANGE',
        NULL = 'NULL',
        EMPTY = 'EMPTY',
    }
    export enum source {
        FLYER = 'FLYER',
        STORE = 'STORE',
    }
    export enum expandSalesFloor {
        REGULAR = 'REGULAR',
        END = 'END',
        WAGON = 'WAGON',
        FLYER = 'FLYER',
    }
    export enum saleType {
        DAILY = 'DAILY',
        WEEKLY = 'WEEKLY',
        WEEKEND = 'WEEKEND',
        MONTHLY = 'MONTHLY',
        OTHER = 'OTHER',
    }
}

