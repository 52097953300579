import {
    FETCH_BUSINESS_FORMAT,
    FETCH_CHANGE_REQUEST_TYPE,
    FETCH_COMPETITOR_CHAINS,
    FETCH_COMPETITOR_STORES,
    FETCH_DEPARTMENTS,
    FETCH_DISTANCES,
    FETCH_DIVISIONS,
    FETCH_EXPAND_SALES_FLOORS,
    FETCH_FREQUENCY_RANKS,
    FETCH_ITEM_CATEGORIES,
    FETCH_PACKING_CATEGORIES,
    FETCH_REGISTRATION_METHODS,
    FETCH_SALE_TYPES,
    FETCH_SOURCES,
    FETCH_STORES,
    FETCH_USER_ORGANIZATION,
    SET_LOADER,
} from './type';
import { CompetitorMasterControllerService, ConstantsControllerService } from '../../services';
import apiErrorHandler from '../../api/apiErrorHandler';

export const setLoader = (data = false) => {
    return {
        type: SET_LOADER,
        data,
    };
};

export const fetchData = (apiCall, successActionType, isLoad = true) => {
    return async (dispatch, getState) => {
        if (isLoad) {
            dispatch(setLoader(true));
        }
        const { isFreshness } = getState().storage;
        apiCall(isFreshness)
            .then((response) => {
                dispatch({ type: successActionType, payload: response });
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error);
            })
            .finally(() => {
                if (isLoad) {
                    dispatch(setLoader(false));
                }
            });
    };
};

export const fetchSources = (isLoad = true) => {
    return (dispatch, getState) => {
        fetchData(ConstantsControllerService.getSources, FETCH_SOURCES, isLoad)(dispatch, getState);
    };
};

export const fetchRegistrationMethods = (isLoad = true) => {
    return (dispatch, getState) => {
        fetchData(ConstantsControllerService.getRegistrationMethods, FETCH_REGISTRATION_METHODS, isLoad)(dispatch, getState);
    };
};

export const fetchDepartments = (isLoad = true) => {
    return (dispatch, getState) => {
        fetchData(ConstantsControllerService.getDepartments, FETCH_DEPARTMENTS, isLoad)(dispatch, getState);
    };
};

export const fetchDivisions = (isLoad = true) => {
    return (dispatch, getState) => {
        fetchData(ConstantsControllerService.getDivisions, FETCH_DIVISIONS, isLoad)(dispatch, getState);
    };
};

export const fetchStores = (isLoad = true) => {
    return (dispatch, getState) => {
        fetchData(ConstantsControllerService.getStores, FETCH_STORES, isLoad)(dispatch, getState);
    };
};

export const fetchCompetitorChains = (isLoad = true) => {
    return (dispatch, getState) => {
        fetchData(CompetitorMasterControllerService.getCompetitorChains, FETCH_COMPETITOR_CHAINS, isLoad)(dispatch, getState);
    };
};

export const fetchCompetitorChainsByStoreCode = (isLoad = true) => {
    return (dispatch, getState) => {
        fetchData(ConstantsControllerService.getCompetitorChainsByStoreCode, FETCH_COMPETITOR_CHAINS, isLoad)(dispatch, getState);
    };
};

export const fetchCompetitorStores = (isLoad = true) => {
    return (dispatch, getState) => {
        fetchData(CompetitorMasterControllerService.getCompetitorStores, FETCH_COMPETITOR_STORES, isLoad)(dispatch, getState);
    };
};

export const fetchCompetitorStoresByStoreCode = (isLoad = true) => {
    return (dispatch, getState) => {
        fetchData(ConstantsControllerService.getCompetitorStoresByStoreCode, FETCH_COMPETITOR_STORES, isLoad)(dispatch, getState);
    };
};

export const fetchItemCategories = (isLoad = true) => {
    return (dispatch, getState) => {
        fetchData(ConstantsControllerService.getItemCategories, FETCH_ITEM_CATEGORIES, isLoad)(dispatch, getState);
    };
};

export const fetchExpandSalesFloors = (isLoad = true) => {
    return (dispatch, getState) => {
        fetchData(ConstantsControllerService.getExpandSalesFloors, FETCH_EXPAND_SALES_FLOORS, isLoad)(dispatch, getState);
    };
};

export const fetchPackingCategories = (isLoad = true) => {
    return (dispatch, getState) => {
        fetchData(ConstantsControllerService.getPackingCategories, FETCH_PACKING_CATEGORIES, isLoad)(dispatch, getState);
    };
};

export const fetchSaleTypes = (isLoad = true) => {
    return (dispatch, getState) => {
        fetchData(ConstantsControllerService.getSaleTypes, FETCH_SALE_TYPES, isLoad)(dispatch, getState);
    };
};

export const fetchDistances = (isLoad = true) => {
    return (dispatch, getState) => {
        fetchData(ConstantsControllerService.getDistances, FETCH_DISTANCES, isLoad)(dispatch, getState);
    };
};

export const fetchFrequencyRanks = (isLoad = true) => {
    return (dispatch, getState) => {
        fetchData(ConstantsControllerService.getFrequencyRanks, FETCH_FREQUENCY_RANKS, isLoad)(dispatch, getState);
    };
};

export const fetchBusinessFormats = (isLoad = true) => {
    return (dispatch, getState) => {
        fetchData(ConstantsControllerService.getBusinessFormats, FETCH_BUSINESS_FORMAT, isLoad)(dispatch, getState);
    };
};

export const fetchUserOrganizations = (isLoad = true) => {
    return (dispatch, getState) => {
        fetchData(ConstantsControllerService.getUserOrganizations, FETCH_USER_ORGANIZATION, isLoad)(dispatch, getState);
    };
};

export const fetchChangeRequestType = (isLoad = true) => {
    return (dispatch, getState) => {
        fetchData(ConstantsControllerService.getChangeRequestTypes, FETCH_CHANGE_REQUEST_TYPE, isLoad)(dispatch, getState);
    };
};
