import { useTranslation } from 'react-i18next';
import Modal from '../../common/Modal';
import { useEffect, useState } from 'react';
import { CompetitorMasterControllerService } from '../../../services';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBusinessFormats, setLoader } from '../../../redux/actions/api';
import { clearErrors } from '../../../redux/actions/error';
import { setToastsSuccess } from '../../../redux/actions/common';
import apiErrorHandler from '../../../api/apiErrorHandler';
import TagInput from '../../common/TagInput';
import Card from '../../common/Card';
import { useLocation } from 'react-router-dom';

const DEFAULT_STATE = {
    apiResponse: null,
    selectedModalCompetitorChainCode: '',
    selectedModalOkStoreCode: '',
    selectedModalStoreCode: '',

    competitorStoreNames: [],
    selectedBusinessFormat: 'GMS_SM',

    deleteIndex: null,
    isShowConfirmDeleteModal: false,
};

const CompetitorChainStoreModal = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const { isShow, handleCloseModal } = props;

    const constants = useSelector((state) => state.constants);
    const { businessFormats } = constants;

    const [state, setState] = useState(DEFAULT_STATE);

    const {
        apiResponse,
        selectedModalCompetitorChainCode,
        selectedModalOkStoreCode,
        selectedModalStoreCode,

        competitorStoreNames,
        selectedBusinessFormat,

        deleteIndex,
        isShowConfirmDeleteModal,
    } = state;

    useEffect(() => {
        dispatch(clearErrors());
        dispatch(fetchBusinessFormats());
    }, [dispatch, location]);

    const handleChangeState = (field, value) => {
        setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleSubmit = () => {
        dispatch(setLoader(true));
        dispatch(clearErrors());
        CompetitorMasterControllerService.createCompetitorChains({
            chainNames: competitorStoreNames,
            businessFormat: selectedBusinessFormat,
        })
            .then(() => {
                dispatch(setToastsSuccess('競合チェーンを登録しました。'));
                setState(DEFAULT_STATE);
                handleCloseModal();
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error, t('message.error.update'));
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleResetAndCloseModal = () => {
        setState(DEFAULT_STATE);
        handleCloseModal();
    };

    const handleTagsChange = (updatedTags) => {
        handleChangeState('competitorStoreNames', updatedTags);
    };

    return (
        <Modal
            size="xl"
            isShow={isShow}
            onClose={handleResetAndCloseModal}
            isShowIconClose="true"
            title="競合チェーン登録"
            btnList={[
                {
                    name: t('update_competitor_master.update-modal.footer.primary-btn'),
                    className: 'btn--primary',
                    onClick: () => handleSubmit(),
                },
                {
                    name: t('update_competitor_master.registration-modal.footer.secondary-btn'),
                    className: 'btn--secondary',
                    onClick: () => handleResetAndCloseModal(),
                },
            ]}
        >
            <div className="okc-card okc-result-form">
                <Card type="modal_card">
                    <div className="row okc-card__row">
                        <div className="col-lg-3 okc-result-form__title">
                            <p>競合チェーン名</p>
                        </div>
                        <div className="col-lg-8 offset-lg-1 d-flex">
                            <div className="row w-100">
                                <div className="col-12 mb-2 js-switch-target" data-select2-id="select2-data-1421-wogk">
                                    <TagInput
                                        type="text"
                                        tags={competitorStoreNames}
                                        onTagsChange={handleTagsChange}
                                        placeholder="競合チェーン名を入力"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>

                <div className="row okc-card__row">
                    <div className="col-lg-3 okc-result-form__title">
                        <p>{t('update_competitor_master.update-modal.form.business_format.label')}</p>
                    </div>
                    <div className="col-lg-8 offset-lg-1 flex-column">
                        <div className="row no-gutters w-100 js-okc-validate">
                            {businessFormats?.map((option, index) => (
                                <div key={index} className={`form-check custom-radio col-xl- col-lg-3 col-md-5`} style={{ minWidth: 0 }}>
                                    <input
                                        className={`${option.label ? 'bases__margin--right4' : ''} form-check-input`}
                                        type="radio"
                                        value={option.value}
                                        name={option.id}
                                        id={option.id}
                                        checked={selectedBusinessFormat === option.value}
                                        onChange={() => handleChangeState('selectedBusinessFormat', option.value)}
                                    />
                                    <label className="form-check-label" htmlFor={option.id}>
                                        {option.label}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CompetitorChainStoreModal;
