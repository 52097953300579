import Modal from '../../common/Modal';
import Card from '../../common/Card';
import SelectSearch from '../../common/SelectSearch';
import { createCompetitorChainOptions } from '../../../utils/helpers/option';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBusinessFormats, fetchCompetitorChains, fetchCompetitorStores, setLoader } from '../../../redux/actions/api';
import { CompetitorMasterControllerService } from '../../../services';
import { setToastsError, setToastsSuccess } from '../../../redux/actions/common';
import Input from '../../common/Input';
import apiErrorHandler from '../../../api/apiErrorHandler';
import { clearErrors } from '../../../redux/actions/error';
import { useLocation } from 'react-router-dom';
import TagInput from '../../common/TagInput';

const DEFAULT_STATE = {
    selectedCompetitorChainCode: null,
    inputCompetitorStoreName: '',
    selectedBusinessFormat: 'GMS_SM',
    competitorStoreNames: [],
};

const CompetitorCombinationMasterModal = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const { isShow, handleCloseModal } = props;

    const constants = useSelector((state) => state.constants);
    const { competitorChains } = constants;

    const [state, setState] = useState(DEFAULT_STATE);

    const {
        selectedCompetitorChainCode,
        isNewChain,
        inputCompetitorChainName,
        inputCompetitorStoreName,
        selectedBusinessFormat,
        competitorStoreNames,
    } = state;

    useEffect(() => {
        dispatch(clearErrors());
        dispatch(fetchCompetitorChains());
        dispatch(fetchBusinessFormats());
    }, [dispatch, location]);

    const handleChangeState = (field, value) => {
        setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const getChainName = (chainCode) => {
        return competitorChains.find((chain) => chain.competitorChainCode === chainCode)?.competitorChainName;
    };

    const handleSubmit = () => {
        if (validation()) return;

        dispatch(setLoader(true));
        dispatch(clearErrors());

        const data = competitorStoreNames.map((item) => `${getChainName(selectedCompetitorChainCode)}　${item}`);

        const request = {
            chainCode: selectedCompetitorChainCode,
            competitorStoreNames: data,
        };
        CompetitorMasterControllerService.createCompetitorStores(request)
            .then(() => {
                dispatch(setToastsSuccess('競合店舗名を登録しました。'));
                setState(DEFAULT_STATE);
                dispatch(fetchCompetitorChains());
                dispatch(fetchCompetitorStores());
                handleCloseModal();
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error, t('message.error.registration'));
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const validation = () => {
        if (!selectedCompetitorChainCode) {
            dispatch(setToastsError('チェーンを選択してください。'));
            return true;
        }
        if (!competitorStoreNames.length > 0) {
            dispatch(setToastsError('競合店名を入力してください。'));
            return true;
        }
        return false;
    };

    const handleResetAndCloseModal = () => {
        setState(DEFAULT_STATE);
        handleCloseModal();
    };

    const handleTagsChange = (updatedTags) => {
        handleChangeState('competitorStoreNames', updatedTags);
    };

    return (
        <Modal
            size="xl"
            isShow={isShow}
            onClose={handleResetAndCloseModal}
            isShowIconClose="true"
            title={t('update_competitor_master.update-modal.header')}
            btnList={[
                {
                    name: t('update_competitor_master.update-modal.footer.primary-btn'),
                    className: 'btn--primary',
                    onClick: () => handleSubmit(),
                    disabled: false,
                },
                {
                    name: t('update_competitor_master.update-modal.footer.secondary-btn'),
                    className: 'btn--secondary',
                    onClick: () => handleResetAndCloseModal(),
                    disabled: false,
                },
            ]}
        >
            <div className="okc-card okc-result-form">
                <Card type="modal_card">
                    <div className="row okc-card__row">
                        <div className="col-lg-3 okc-result-form__title">
                            <p>{t('update_competitor_master.update-modal.form.chain_name.label')}</p>
                        </div>
                        <div className="col-lg-8 offset-lg-1 d-flex">
                            <div className="row w-100">
                                <div className="col-12 mb-2">
                                    <SelectSearch
                                        onChange={(option) => handleChangeState('selectedCompetitorChainCode', option.value)}
                                        options={createCompetitorChainOptions(competitorChains)}
                                        value={selectedCompetitorChainCode}
                                        placeholder={t('price_request.competitor_name.placeholder')}
                                    />
                                </div>
                                <div className="error-message-wrap">
                                    <p className="okc-error mt-2 mb-1 chain-name-error d-none">
                                        {t('competitor.name.is.already.registered')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row okc-card__row">
                        <div className="col-lg-3 okc-result-form__title">
                            <p>{t('update_competitor_master.update-modal.form.competitive_store.label')}</p>
                        </div>
                        <div className="col-lg-8 offset-lg-1 d-flex">
                            <div className="col-auto competitor-store-prefix-wrap">
                                {selectedCompetitorChainCode && (
                                    <div className="" id="competitor-store-prefix" style={{ marginBottom: '5px', marginRight: '15px' }}>
                                        {getChainName(selectedCompetitorChainCode)}
                                    </div>
                                )}
                            </div>
                            <div className="row w-100">
                                <div className="col-12 mb-2" data-switch="2">
                                    {/* <Input
                                        className={`form-control form-control--input competitor-store-name-input`}
                                        id="u-store-name-input"
                                        type="text"
                                        placeholder={t('update_competitor_master.update-modal.form.competitive_store.input.placeholder')}
                                        maxlength="30"
                                        autocomplete="off"
                                        value={inputCompetitorStoreName}
                                        onChange={(value) => handleChangeState('inputCompetitorStoreName', value)}
                                    /> */}

                                    <TagInput
                                        type="text"
                                        tags={competitorStoreNames}
                                        onTagsChange={handleTagsChange}
                                        placeholder="競合店舗名を入力"
                                    />
                                </div>
                                <p className="okc-error mt-2 mb-1 d-none chain-store-name-error">
                                    {t('competitor.name.is.already.registered')}
                                </p>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </Modal>
    );
};

export default CompetitorCombinationMasterModal;
