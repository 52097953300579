/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type EmailReceiverRequest = {
    email?: string;
    receiverType?: EmailReceiverRequest.receiverType;
    departmentCode?: number;
    valid?: boolean;
};
export namespace EmailReceiverRequest {
    export enum receiverType {
        NEARBY_STORE = 'NEARBY_STORE',
        DISSOCIATED = 'DISSOCIATED',
        OBSERVATION = 'OBSERVATION',
        BELOW_COST = 'BELOW_COST',
    }
}

