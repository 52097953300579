/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ExportChangeRequestRequest = {
    selectedStoreCode?: string;
    changeRequestType?: ExportChangeRequestRequest.changeRequestType;
    startDateMin?: string;
    startDateMax?: string;
    selectedDepartment?: number;
    selectedLine?: number;
    selectedClass?: number;
    selectedSubclass?: number;
};
export namespace ExportChangeRequestRequest {
    export enum changeRequestType {
        COMPETITOR = 'COMPETITOR',
        FRESHNESS = 'FRESHNESS',
        INSTRUCTION = 'INSTRUCTION',
    }
}

