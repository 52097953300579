import { useTranslation } from 'react-i18next';
import Modal from '../../common/Modal';
import Button from '../../common/Button';

const CompetitorCombinationSelectModal = (props) => {
    const { t } = useTranslation();
    const { isShow, handleCloseModal, showForStoreModal, showForCompetitorModal } = props;

    return (
        <Modal
            size="xl"
            isShow={isShow}
            onClose={handleCloseModal}
            isShowIconClose="true"
            title={t('update_competitor_master.registration-modal.header')}
            btnList={[
                {
                    name: t('update_competitor_master.registration-modal.footer.secondary-btn'),
                    className: 'btn--secondary',
                    onClick: () => handleCloseModal(),
                },
            ]}
        >
            <div className="d-flex justify-content-center align-items-center fade-in">
                <Button
                    className="btn btn--primary"
                    onClick={() => {
                        showForStoreModal();
                        handleCloseModal();
                    }}
                >
                    <span className="btn-text">{t('update_competitor_master.button.competitor_combination_masters_for_store')}</span>
                </Button>
                <Button
                    className="btn btn--primary ml-5"
                    onClick={() => {
                        showForCompetitorModal();
                        handleCloseModal();
                    }}
                >
                    <span className="btn-text">{t('update_competitor_master.button.competitor_combination_masters_for_competitor')}</span>
                </Button>
            </div>
        </Modal>
    );
};

export default CompetitorCombinationSelectModal;
