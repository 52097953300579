import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../common/Button';
import '../../assets/css/common/table.css';
import '../../assets/css/common/common.css';
import ImageModal from '../common/ImageModal';
import { setSort } from '../../redux/actions/common';
import { useDispatch, useSelector } from 'react-redux';

const EditTable = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { confirmStatus, heads, body, dataTable = [], setDataTable, isHeightTr, onlyTab, normal, dataText, discounts = [] } = props;

    // 申請画面
    const isRequestingPage = confirmStatus === 'REQUESTING';
    // 承認画面
    const isConfirmPage = confirmStatus === 'NOT_CONFIRM';
    // 差戻画面
    const isRemandPage = confirmStatus === 'REMAND';

    // 申請画面、差戻画面のチェックボックスフィールド
    const REQUESTING_CHECKBOX_FIELD = 'selected';
    // 承認画面のチェックボックスフィールド（承認）
    const CONFIRMED_CHECKBOX_FIELD = 'confirmed';
    // 承認画面のチェックボックスフィールド（差戻）
    const REMAND_CHECKBOX_FIELD = 'remand';

    const [isCheckAllFirst, setIsCheckAllFirst] = useState(false);
    const [isCheckAllSecond, setIsCheckAllSecond] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);

    const sort = useSelector((state) => state.sort);

    const handleOpenModal = (imageUrl) => {
        if (imageUrl) {
            setImageUrl(imageUrl);
            setShowModal(true);
        }
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };

    const toggleCheckboxAll = (field, setIsCheckAll, value) => {
        const newDataTable = dataTable.map((item) => {
            if (isConfirmPage && value) {
                // 承認画面で一括選択の場合は他のチェックボックスを解除する
                return {
                    ...item,
                    [CONFIRMED_CHECKBOX_FIELD]: false,
                    [REMAND_CHECKBOX_FIELD]: false,
                    [field]: value,
                };
            }
            return { ...item, [field]: value };
        });
        setDataTable(newDataTable);
        setIsCheckAllFirst(false);
        setIsCheckAllSecond(false);
        setIsCheckAll(value);
    };

    const toggleCheckbox = (field, id) => {
        const newDataTable = dataTable.map((item) => {
            if (item.id === id) {
                if (isConfirmPage) {
                    return {
                        ...item,
                        [CONFIRMED_CHECKBOX_FIELD]: false,
                        [REMAND_CHECKBOX_FIELD]: false,
                        [field]: !item[field],
                    };
                }
                return { ...item, [field]: !item[field] };
            }
            return item;
        });
        setDataTable(newDataTable);
    };

    const renderHeads = () => {
        const headLength = heads?.filter((head) => head && !head.disabled).length;
        return (
            <>
                {normal && (
                    <tr>
                        <th className="invisible column" colSpan={heads && headLength > 1 ? headLength - 1 : 1}></th>
                        <th
                            className="checkbox freeze-column freeze-right-0"
                            style={{ padding: '0px', backgroundColor: '#f8fafc', borderRight: 0 }}
                        >
                            <button
                                className={`btn price-request__all ${isCheckAllFirst ? 'btn--secondary' : 'btn--primary'}`}
                                onClick={() =>
                                    isCheckAllFirst
                                        ? toggleCheckboxAll(REQUESTING_CHECKBOX_FIELD, setIsCheckAllFirst, false)
                                        : toggleCheckboxAll(REQUESTING_CHECKBOX_FIELD, setIsCheckAllFirst, true)
                                }
                            >
                                {isCheckAllFirst ? t('button.release_all') : t('button.check_all')}
                            </button>
                        </th>
                    </tr>
                )}
                {onlyTab && (
                    <tr>
                        <th className="invisible column" colSpan={heads && headLength > 1 ? headLength - 1 : 1}></th>
                    </tr>
                )}
                {(isRequestingPage || isRemandPage) && (
                    // 申請画面、差戻画面の場合
                    <tr>
                        <th className="invisible column" colSpan={heads && headLength > 1 ? headLength - 1 : 1}></th>
                        <th className="checkbox freeze-column freeze-right-0" style={{ padding: '0px' }}>
                            <button
                                className={`btn price-request__all ${isCheckAllFirst ? 'btn--secondary' : 'btn--primary'}`}
                                onClick={() =>
                                    isCheckAllFirst
                                        ? toggleCheckboxAll(REQUESTING_CHECKBOX_FIELD, setIsCheckAllFirst, false)
                                        : toggleCheckboxAll(REQUESTING_CHECKBOX_FIELD, setIsCheckAllFirst, true)
                                }
                            >
                                {isCheckAllFirst ? t('button.release_all') : t('button.check_all')}
                            </button>
                        </th>
                    </tr>
                )}
                {isConfirmPage && (
                    // 承認画面の場合
                    <tr>
                        <th className="invisible column" colSpan={heads && headLength > 1 ? headLength - 3 : 1}></th>
                        <th className={`checkbox freeze-column`} style={{ padding: '0px' }}>
                            <button
                                className={`btn confirm-registration__all-set ${isCheckAllFirst ? 'btn--secondary' : 'btn--primary'}`}
                                onClick={() =>
                                    isCheckAllFirst
                                        ? toggleCheckboxAll(CONFIRMED_CHECKBOX_FIELD, setIsCheckAllFirst, false)
                                        : toggleCheckboxAll(CONFIRMED_CHECKBOX_FIELD, setIsCheckAllFirst, true)
                                }
                            >
                                {isCheckAllFirst ? t('button.release_all') : t('button.check_all')}
                            </button>
                        </th>
                        <th className={`checkbox freeze-column`} style={{ padding: '0px' }}>
                            <button
                                className={`btn confirm-registration__all-remand ${isCheckAllSecond ? 'btn--secondary' : 'btn--primary'}`}
                                onClick={() =>
                                    isCheckAllSecond
                                        ? toggleCheckboxAll(REMAND_CHECKBOX_FIELD, setIsCheckAllSecond, false)
                                        : toggleCheckboxAll(REMAND_CHECKBOX_FIELD, setIsCheckAllSecond, true)
                                }
                            >
                                {isCheckAllSecond ? t('button.release_all') : t('button.check_all')}
                            </button>
                        </th>
                        <th className="invisible"></th>
                    </tr>
                )}
                <tr>
                    {heads?.map((head, index) => {
                        if (Object.keys(head).length === 0 || head.disabled) {
                            return <></>;
                        }

                        return (
                            <th
                                key={index}
                                colSpan={head.colSpan}
                                className={`v-select-default ${head.field ? 'sort' : ''} ${sort.sortField === head.field && sort.sortType === 'DESC' ? 'active' : ''} ${head.className}`}
                                onClick={() => {
                                    if (!head.field) {
                                        return;
                                    }
                                    const sortType = sort.sortField === head.field ? (sort.sortType === 'DESC' ? 'ASC' : 'DESC') : 'ASC';
                                    dispatch(
                                        setSort({
                                            sortField: head.field,
                                            sortType: sortType,
                                        }),
                                    );
                                }}
                            >
                                <>{head?.title}</>
                            </th>
                        );
                    })}
                </tr>
            </>
        );
    };

    const renderRowValue = (itemColumn, itemRow) => {
        let rowValue = null;
        const fields = itemColumn?.field?.split('.') ?? [];

        for (const field of fields) {
            rowValue = !rowValue ? itemRow[field ?? ''] : rowValue[field];
        }

        // PB・留型
        if (itemColumn?.field === 'nbpbkbn') {
            rowValue = rowValue === 1 || rowValue === 2 ? '〇' : '-';
        }

        if (rowValue === null) {
            return '-';
        }

        if (rowValue) {
            if (itemColumn?.mappingData) {
                return itemColumn?.mappingData.find((item) => item.value === rowValue)?.label ?? rowValue;
            }
            return rowValue;
        } else {
            return '-';
        }
    };

    const discountClass = (discount) => {
        switch (discount) {
            case '10%':
                return 'warning';
            case '30%':
                return 'deviate-warning';
            case 'linkedItem':
                return 'linked-item';
            default:
                return '';
        }
    };

    const renderRows = () => {
        return (
            <>
                {dataTable.length > 0 ? (
                    <>
                        {dataTable?.map((itemRow, indexRow) => (
                            <tr key={indexRow} className={`${discountClass(discounts[indexRow])} ${isHeightTr ? 'v-minHeight-46' : ''}`}>
                                {body?.columns
                                    ?.filter((item) => !!item)
                                    .map((itemColumn, indexColumn) => {
                                        if (Object.keys(itemColumn).length === 0 || itemColumn.disabled) {
                                            return <></>;
                                        }
                                        return (
                                            <td
                                                key={indexColumn}
                                                className={`${itemColumn.isButton ? 'components__table-btn_body' : ''} ${
                                                    itemColumn?.isBorder ? 'cell-border' : ''
                                                } ${itemColumn?.className ?? ''}`}
                                                onClick={(event) => {
                                                    if (itemColumn?.isLink && itemRow?.imageUrl) {
                                                        event.stopPropagation();
                                                        event.preventDefault();
                                                        handleOpenModal(itemRow?.imageUrl);
                                                    }
                                                }}
                                            >
                                                {itemColumn.isInput ? (
                                                    itemRow[itemColumn?.field ?? ''] !== undefined && (
                                                        <div className="form-textarea">
                                                            <textarea
                                                                disabled={!isConfirmPage}
                                                                value={itemRow[itemColumn?.field ?? '']}
                                                                className={`form-control ${itemColumn?.className ?? ''}`}
                                                                onInput={(event) => {
                                                                    itemColumn.onChange(itemRow.id, event.target.value);
                                                                }}
                                                                autoComplete="off"
                                                                maxLength={itemColumn?.maxLength}
                                                            />
                                                        </div>
                                                    )
                                                ) : itemColumn.isCheckbox ? (
                                                    <div className={`form-check custom-checkbox`}>
                                                        <input
                                                            type="checkbox"
                                                            id={itemColumn.field + itemRow.id}
                                                            className="form-check-input"
                                                            name={itemColumn.field + itemRow.id}
                                                            checked={
                                                                itemColumn?.reverse ? !itemRow[itemColumn.field] : itemRow[itemColumn.field]
                                                            }
                                                            onChange={() => {
                                                                toggleCheckbox(itemColumn.field, itemRow.id);
                                                            }}
                                                            disabled={itemColumn?.checkboxDisabled}
                                                        />
                                                        <label className="form-check-label" htmlFor={itemColumn.field + itemRow.id}></label>
                                                    </div>
                                                ) : itemColumn.isButtonDelete ? (
                                                    <button
                                                        className="btn btn--icon btn--secondary confirm-delete-modal"
                                                        onClick={() => itemColumn.onClick(indexRow, itemRow)}
                                                    >
                                                        <i className="icon-bin"></i>
                                                        <span className="btn-text"></span>
                                                    </button>
                                                ) : itemColumn.isButtonEdit ? (
                                                    <Button
                                                        className="btn btn--icon btn--primary confirm-delete-modal"
                                                        onClick={() => itemColumn.onClick(indexRow, itemRow)}
                                                    >
                                                        <i className="icon-edit"></i>
                                                        <span className="btn-text"></span>
                                                    </Button>
                                                ) : (
                                                    <span
                                                        className={
                                                            itemColumn?.isLink
                                                                ? itemRow?.imageUrl
                                                                    ? 'text-orange components__table-link'
                                                                    : 'components__table-link'
                                                                : ''
                                                        }
                                                    >
                                                        {renderRowValue(itemColumn, itemRow)}
                                                    </span>
                                                )}
                                            </td>
                                        );
                                    })}
                            </tr>
                        ))}
                    </>
                ) : (
                    <>
                        <tr className="no-result">
                            <td colSpan={heads?.length || 1}>{dataText ?? 'データが存在しません。'}</td>
                        </tr>
                    </>
                )}
            </>
        );
    };

    return (
        <div>
            <ImageModal show={showModal} handleClose={handleCloseModal} imageUrl={imageUrl} />
            <div className={`okc-table components__table-wrapper bases__padding--bottom20`}>
                <div className="okc-table__wrapper table-responsive">
                    <table className="w-100">
                        <thead>{renderHeads()}</thead>
                        <tbody>{renderRows()}</tbody>
                    </table>
                </div>
            </div>
        </div>
    );
});

export default EditTable;
