import '../../assets/css/components/side-bar.css';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import * as routes from '../../utils/constants/routes';
import { useEffect, useState } from 'react';

const Header = () => {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();

    const openMenu = (isOpen) => {
        const el = document.getElementsByClassName('sb-slide-right');
        if (isOpen) {
            el[0].classList.remove('okc-sidebar--compact');
        } else {
            el[0].classList.add('okc-sidebar--compact');
        }
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        setIsOpen(false);
        openMenu();
    }, [location]);
    return (
        <>
            <div className="okc-header h-slide-bottom">
                <button onClick={() => openMenu(isOpen)} className="btn btn--icon btn--square okc-header__sidebar-trigger">
                    <i className="icon-menu"></i>
                    <span className="btn-text"></span>
                </button>
                <div className="okc-header__company">{t('okc.title.header')}</div>
            </div>
        </>
    );
};

export default Header;
