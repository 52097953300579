import { useEffect, useState } from 'react';
import Pagination from '../../common/Pagination';
import Button from '../../common/Button';
import Modal from '../../common/Modal';
import '../../../assets/css/pages/ITheadquarters/prohibited-item.css';
import ModalProhibited from '../../layout/ITHeadquarters/ModalProhibited';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setLoader } from '../../../redux/actions/api';
import EditTable from '../../tables/EditTable';
import { getExceptionItemTable, getProhibitedCategoryTable, getProhibitedItemTable } from '../../tables/table';
import {
    ExceptionControllerService,
    ProhibitedControllerService as ProhibitedItemControllerService,
    ProhibitedControllerService,
} from '../../../services';
import { setToastsSuccess } from '../../../redux/actions/common';
import env from '../../../configs/env';
import ProhibitedForm from '../../layout/ITHeadquarters/ProhibitedForm';
import apiErrorHandler from '../../../api/apiErrorHandler';
import { clearErrors } from '../../../redux/actions/error';
import { useLocation } from 'react-router-dom';

const ProhibitedItem = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        dispatch(clearErrors());
    }, [location, dispatch]);

    const { isException } = props;

    const sort = useSelector((state) => state.sort);

    const DEFAULT_STATUS = {
        itemNames: '',
        jan: '',
        departments: [],
        lines: [],
        classes: [],
        subclasses: [],
        selectedDivision: {},
        selectedDepartment: '',
        selectedLine: '',
        selectedClass: '',
        selectedSubclass: '',
        lowerLimitFlag: isException ? undefined : false,
        deleteFlag: isException ? undefined : false,
        selectedLowestPriceType: 'COMPETITOR',
        prohibitedSearchType: 'PRODUCT',
        prohibitedSearchTypeOld: 'PRODUCT',
        isEditShowModal: false,
        startDate: '',
        isCheckStartDate: false,
        endDate: '',
        isCheckEndDate: false,
        isDeleteConfirm: false,
        isDeleteContinue: false,

        page: 1,
        limit: 20,
        apiResponse: null,
        reload: new Date(),
    };

    const [state, setState] = useState(DEFAULT_STATUS);

    const {
        itemNames,
        jan,
        selectedDepartment,
        selectedLine,
        selectedClass,
        selectedSubclass,
        lowerLimitFlag,
        deleteFlag,
        selectedLowestPriceType,
        prohibitedSearchType,
        prohibitedSearchTypeOld,
        isEditShowModal,
        startDate,
        endDate,
        isDeleteConfirm,
        isDeleteContinue,

        page,
        limit,
        apiResponse,
    } = state;

    const [formData, setFormData] = useState(null);

    const handleEditClick = (index, value) => {
        setFormData(value);
        handleChangeState('isEditShowModal', true);
    };

    const handleDeleteClick = (index, value) => {
        setFormData(value);
        handleChangeState('isDeleteConfirm', true);
    };

    const deleteItem = () => {
        dispatch(setLoader(true));
        dispatch(clearErrors());
        ExceptionControllerService.upsertExceptionItems({
            jans: [formData.jan],
            lowestPriceType: formData.lowestPriceType,
            deleteFlag: true,
        })
            .then(() => {
                dispatch(setToastsSuccess('削除が完了しました。'));
                setTimeout(getAction, 500);
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error, t('message.error.delete'));
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const table = isException ? getExceptionItemTable(t, handleDeleteClick) : getProhibitedItemTable(handleEditClick);
    const tableCategory = getProhibitedCategoryTable();

    useEffect(() => {
        if (isDeleteContinue) {
            // 削除確認モーダル表示後に処理を続行する場合
            deleteItem();
            handleChangeState('isDeleteContinue', false);
        }
        if (prohibitedSearchType === prohibitedSearchTypeOld) {
            getAction();
        }
    }, [dispatch, isDeleteContinue, page, sort]);

    const getAction = () => {
        setState((prevState) => ({
            ...prevState,
            prohibitedSearchTypeOld: prohibitedSearchType,
        }));
        isException
            ? prohibitedSearchType === 'PRODUCT'
                ? getExceptionItems()
                : getCategories()
            : prohibitedSearchType === 'PRODUCT'
              ? getProhibitedItems()
              : getCategories();
    };

    const getProhibitedItems = () => {
        dispatch(setLoader(true));
        dispatch(clearErrors());
        ProhibitedItemControllerService.getProhibitedItems({
            itemNames: itemNames !== '' ? itemNames?.split(/[\s\u3000]+/) : null,
            jans: jan !== '' ? jan?.split(/[\s\u3000]+/) : null,
            departmentCode: selectedDepartment,
            lineCode: selectedLine,
            classCode: selectedClass,
            subclassCode: selectedSubclass,
            lowerLimitFlag: lowerLimitFlag,
            deleteFlag: deleteFlag,
            lowestPriceType: selectedLowestPriceType,
            startDate: startDate,
            endDate: endDate,
            page: page,
            limit: limit,
            sortField: table.heads.find((head) => head.field === sort.sortField)?.field,
            sortType: sort.sortType,
        })
            .then((response) => {
                setState((prevState) => ({
                    ...prevState,
                    apiResponse: response,
                }));
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const getExceptionItems = () => {
        dispatch(setLoader(true));
        dispatch(clearErrors());
        ExceptionControllerService.getExceptionItems({
            itemNames: itemNames !== '' ? itemNames?.split(/[\s\u3000]+/) : null,
            jans: jan !== '' ? jan?.split(/[\s\u3000]+/) : null,
            departmentCode: selectedDepartment,
            lineCode: selectedLine,
            classCode: selectedClass,
            subclassCode: selectedSubclass,
            lowestPriceType: selectedLowestPriceType,
            startDate: startDate ? startDate : null,
            endDate: endDate ? endDate : null,
            page: page,
            limit: limit,
            sortField: sort.sortField,
            sortType: sort.sortType,
        })
            .then((response) => {
                setState((prevState) => ({
                    ...prevState,
                    apiResponse: response,
                }));
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const getCategories = () => {
        dispatch(setLoader(true));
        dispatch(clearErrors());
        const requestData = {
            departmentCode: selectedDepartment,
            lineCode: selectedLine,
            classCode: selectedClass,
            subclassCode: selectedSubclass,
            lowestPriceType: selectedLowestPriceType,
            startDate: startDate ? startDate : null,
            endDate: endDate ? endDate : null,

            page: page,
            limit: limit,
            sortField: tableCategory.heads.find((head) => head.field === sort.sortField)?.field,
            sortType: sort.sortType,
        };

        const request = isException
            ? ExceptionControllerService.getExceptionCategories(requestData)
            : ProhibitedControllerService.getProhibitedCategories(requestData);
        request
            .then((response) => {
                setState((prevState) => ({
                    ...prevState,
                    apiResponse: response,
                }));
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleChangeState = (field, value) => {
        setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handlePageClick = (event) => {
        handleChangeState('page', event.selected + 1);
    };

    const handleDownload = () => {
        // バックエンドからダウンロードするため、formを作成してリクエスト
        const form = document.createElement('form');
        const path = isException ? 'exception' : 'prohibited';
        form.action = `${env.BACKEND_BASE_URL}/api/${path}/export`;
        form.method = 'POST';
        document.body.append(form);

        const request = isException
            ? {
                  // 例外商品の場合は検索レスポンスのJANをリクエストに追加
                  jans: apiResponse?.data?.map((item) => item.jan),
                  startDate: startDate ? startDate : null,
                  endDate: endDate ? endDate : null,
              }
            : {
                  // 禁止商品の場合は検索パラメータをリクエストに追加
                  itemNames: itemNames !== '' ? itemNames?.split(/[\s\u3000]+/) : null,
                  jans: jan !== '' ? jan?.split(/[\s\u3000]+/) : null,
                  departmentCode: selectedDepartment,
                  lineCode: selectedLine,
                  classCode: selectedClass,
                  subclassCode: selectedSubclass,
                  lowerLimitFlag,
                  deleteFlag,
                  lowestPriceType: selectedLowestPriceType,
                  startDate: startDate ? startDate : null,
                  endDate: endDate ? endDate : null,
              };

        form.addEventListener('formdata', (e) => {
            const fd = e.formData;
            for (const key in request) {
                if (request[key]) {
                    fd.append(key, request[key]);
                }
            }
        });

        form.submit();
        form.remove();
    };

    const handleSubmitEditFormData = () => {
        dispatch(clearErrors());
        ProhibitedControllerService.upsertProhibitedItems({
            data: [formData],
        })
            .then(() => {
                dispatch(setToastsSuccess('編集が完了しました。'));
                handleChangeState('isEditShowModal', false);
                getAction();
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error, t('message.error.update'));
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleClearForm = () => {
        setState(DEFAULT_STATUS);
    };

    const handleChangeType = () => {
        setState((prevState) => ({
            ...prevState,
            apiResponse: null,
            page: 1,
        }));
    };

    return (
        <div className="okc-container prohibited-products">
            <div className="okc-inner">
                <div>
                    <div className="okc-title">
                        <div className="okc-title__wrapper fade-in">
                            <div className="okc-title">{isException ? '例外商品一覧・検索' : '禁止商品一覧・検索'}</div>
                            <a className="btn btn--primary ml-2" href={isException ? '/exception-item/import' : '/prohibited-item/import'}>
                                <span className="btn-text">{t('prohibited_product_list.search_form.register_excel_button')}</span>
                            </a>
                            <a
                                className="btn btn--primary ml-2"
                                href={isException ? '/exception-category/register' : '/prohibited-category/register'}
                            >
                                <span className="btn-text">{t('prohibited_product_list.search_form.category_registration_button')}</span>
                            </a>
                        </div>
                    </div>
                    <ProhibitedForm
                        state={state}
                        setState={setState}
                        handleSubmit={getAction}
                        handleClear={handleClearForm}
                        onChangeType={handleChangeType}
                    />
                    {apiResponse !== null && prohibitedSearchType === 'PRODUCT' && (
                        <>
                            <Pagination pageCount={apiResponse?.totalPage} onPageChange={handlePageClick} />
                            <EditTable
                                isHeightTr
                                heads={table.heads}
                                body={table.body}
                                dataTable={apiResponse?.data}
                                dataText={'該当商品が見つかりません。'}
                            />
                            <Button className="btn btn--primary w-100" id="download-excel-btn" type="button" onClick={handleDownload}>
                                <span className="btn-text">{t('prohibited_product_list.search_form.download_button')}</span>
                            </Button>
                        </>
                    )}
                    {apiResponse !== null && prohibitedSearchType !== 'PRODUCT' && (
                        <>
                            <Pagination pageCount={apiResponse?.totalPage} onPageChange={handlePageClick} />
                            <EditTable
                                isHeightTr
                                heads={tableCategory.heads}
                                body={tableCategory.body}
                                dataTable={apiResponse?.data}
                                dataText={'該当商品が見つかりません。'}
                            />
                            <Button className="btn btn--primary w-100" id="download-excel-btn" type="button" onClick={handleDownload}>
                                <span className="btn-text">{t('prohibited_product_list.search_form.download_button')}</span>
                            </Button>
                        </>
                    )}
                    <Modal
                        size="xl"
                        isShow={isEditShowModal}
                        onClose={() => handleChangeState('isEditShowModal', false)}
                        isShowIconClose="true"
                        title={t('prohibited_product_list.edit_modal.title')}
                        btnList={[
                            {
                                name: t('prohibited_product_list.edit_modal.update_button'),
                                className: 'btn--primary',
                                onClick: () => handleSubmitEditFormData(),
                                disabled: false,
                            },
                        ]}
                    >
                        <ModalProhibited formData={formData} setFormData={setFormData} />
                    </Modal>
                    <Modal
                        size="sm"
                        isShow={isDeleteConfirm}
                        onClose={() => handleChangeState('isDeleteConfirm', false)}
                        isShowIconClose="true"
                        title={t('exception_product_list.modal_delete.title')}
                        btnList={[
                            {
                                name: t('button.delete'),
                                className: 'btn--primary',
                                onClick: () => {
                                    handleChangeState('isDeleteContinue', true);
                                    handleChangeState('isDeleteConfirm', false);
                                },
                                disabled: false,
                            },
                            {
                                name: t('button.cancel'),
                                className: 'btn--secondary master-edit-modal__submit',
                                onClick: () => handleChangeState('isDeleteConfirm', false),
                                disabled: false,
                            },
                        ]}
                        children={
                            <>
                                <p className="text-center">{t('exception_product_list.modal_delete.body.first_content')}</p>
                                <p className="text-center">{t('exception_product_list.modal_delete.body.second_content')}</p>
                            </>
                        }
                    />
                </div>
            </div>
        </div>
    );
};
export default ProhibitedItem;
