import { forwardRef } from 'react';
import '../../assets/css/common/table.css';
import '../../assets/css/common/common.css';
import { Link } from 'react-router-dom';

const SurveyTable = forwardRef((props, ref) => {
    const { heads, body, onClickModal } = props;

    const getStoreRank = (storeRank) => {
        const STORE_RANK = { 0: '週2回〜', 1: '週1回', 2: '隔週', 3: '月1回' };
        return STORE_RANK[storeRank];
    };

    return (
        <table className="okc-table__main w-100" id="survey-history-table">
            <thead>
                <tr>
                    {heads &&
                        heads.map((itemHeader, index) => (
                            <th key={index} {...(index !== 0 && { 'data-column': 'none' })}>
                                {itemHeader.name}
                            </th>
                        ))}
                </tr>
            </thead>
            {body &&
                body.map((itemRow, index) => (
                    <tbody key={index}>
                        <tr className="no-result">
                            <th rowSpan="2">
                                <div className="d-flex">
                                    <Link to={`/select-store?competitorStoreCode=${itemRow?.competitorStoreCode}`}>
                                        {itemRow.competitorName}
                                    </Link>
                                    <Link className="link" to={itemRow.googleDriverUrl} target="_blank">
                                        <i className="icon-drive"></i>
                                    </Link>
                                </div>
                            </th>
                            <td
                                className={`modal-link frequency ${itemRow.highlight ? 'highlight' : ''}`}
                                rowSpan="2"
                                data-toggle="modal"
                                data-column="none"
                                onClick={() =>
                                    onClickModal(
                                        itemRow?.competitorStoreMasterId,
                                        itemRow?.competitorName,
                                        itemRow?.systemRank,
                                        itemRow?.surveyFrequencyUnitWeek,
                                    )
                                }
                            >
                                {getStoreRank(itemRow.surveyFrequencyUnitWeek)}
                            </td>
                            <td className={`${itemRow.highlight ? 'highlight' : ''}`} rowSpan="2" data-column="none">
                                {itemRow?.uninvestigatedDays}
                            </td>
                            {itemRow?.surveyList.map((item, index) => {
                                if (item.requesterDate != null) {
                                    return (
                                        <td key={index} className={`${itemRow.highlight ? 'highlight' : ''}`} data-column="none">
                                            {item.requesterDate}
                                        </td>
                                    );
                                } else {
                                    return (
                                        <td className="empty" rowSpan="2" key={index} data-column="none">
                                            -
                                        </td>
                                    );
                                }
                            })}
                        </tr>
                        <tr>
                            {itemRow?.surveyList.map((item, index) => {
                                if (item.requesterName != null) {
                                    return (
                                        <td key={index} className={`${itemRow.highlight ? 'highlight' : ''}`} data-column="none">
                                            {item.requesterName}
                                        </td>
                                    );
                                }
                            })}
                        </tr>
                    </tbody>
                ))}
        </table>
    );
});

export default SurveyTable;
