/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CompetitorChainSearchResult = {
    id?: number;
    chainCode?: string;
    chainName?: string;
    businessFormat?: CompetitorChainSearchResult.businessFormat;
    businessFormatName?: string;
};
export namespace CompetitorChainSearchResult {
    export enum businessFormat {
        GMS_SM = 'GMS_SM',
        SM_LOCAL = 'SM_LOCAL',
        DISCOUNT = 'DISCOUNT',
        DRAG = 'DRAG',
        HOME_CENTER = 'HOME_CENTER',
        SPECIALTY_SHOP = 'SPECIALTY_SHOP',
        OTHER = 'OTHER',
    }
}

