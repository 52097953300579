/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ProhibitedItemSearchRequest = {
    page?: number;
    limit?: number;
    sortField?: string;
    sortType?: ProhibitedItemSearchRequest.sortType;
    itemNames?: Array<string>;
    jans?: Array<string>;
    departmentCode?: number;
    lineCode?: number;
    classCode?: number;
    subclassCode?: number;
    lowestPriceType?: ProhibitedItemSearchRequest.lowestPriceType;
    lowerLimitFlag?: boolean;
    deleteFlag?: boolean;
};
export namespace ProhibitedItemSearchRequest {
    export enum sortType {
        ASC = 'ASC',
        DESC = 'DESC',
    }
    export enum lowestPriceType {
        EDLP = 'EDLP',
        SARATOKU = 'SARATOKU',
        CHOME = 'CHOME',
        COMPETITOR = 'COMPETITOR',
        FRESHNESS = 'FRESHNESS',
        POS_CHANGED = 'POS_CHANGED',
        ORIGINAL_SELLING_PRICE_CHANGE = 'ORIGINAL_SELLING_PRICE_CHANGE',
        NULL = 'NULL',
        EMPTY = 'EMPTY',
    }
}

