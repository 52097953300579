import { BrowserMultiFormatReader } from '@zxing/library';
import { useEffect, useMemo, useRef, useState } from 'react';

const CodeReader = (props) => {
    const { onChange, isShow, onClose } = props;
    const onReadCode = (result) => {
        onChange(result);
    };
    const videoRef = useRef(null);
    const codeReader = useMemo(() => new BrowserMultiFormatReader(), []);

    useEffect(() => {
        let stream = null;

        const startDecoding = async () => {
            if (!videoRef.current) return;

            try {
                stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
                videoRef.current.srcObject = stream;

                await codeReader.decodeFromVideoDevice(undefined, videoRef.current, (result) => {
                    if (result) {
                        onReadCode?.(result);
                        stopDecoding();
                    }
                });
            } catch (error) {}
        };

        const stopDecoding = async () => {
            onClose();
            if (stream) {
                stream.getTracks().forEach((track) => track.stop());
                if (videoRef.current) {
                    videoRef.current.srcObject = null;
                }
            }
        };

        startDecoding();

        return () => {
            if (stream) {
                stream.getTracks().forEach((track) => track.stop());
            }
            codeReader.reset();
        };
    }, [codeReader, onReadCode, isShow]);

    return isShow ? (
        // 16:9
        <div style={{ position: 'relative', width: '100%', paddingBottom: '56.25%' }}>
            <video
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    padding: 0,
                    objectFit: 'cover',
                }}
                ref={videoRef}
                autoPlay
            />
        </div>
    ) : (
        <></>
    );
};

export default CodeReader;
