import { useTranslation } from 'react-i18next';
import Card from '../../common/Card';
import Label from '../../common/Label';
import Input from '../../common/Input';
import SelectSearch from '../../common/SelectSearch';
import Choice from '../../common/Choice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { setCompetitorStore, setDepartments, setRequesterName, setRegistrationMethod, setSource } from '../../../redux/actions/storage';
import {
    fetchCompetitorStores,
    fetchCompetitorStoresByStoreCode,
    fetchDepartments,
    fetchRegistrationMethods,
    fetchSources,
} from '../../../redux/actions/api';

const DepartmentSelect = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useNavigate();

    const { paramCompetitorStoreCode } = props;

    const storage = useSelector((state) => state.storage);
    const { isStore, isHeadquarter, isCompetitor, isFreshness, isInstruction } = storage;

    const constants = useSelector((state) => state.constants);
    const { sources, registrationMethods, departments, competitorStores } = constants;

    const departmentsAll = [
        {
            id: 'all',
            label: t('select_store.okc.check_all'),
            value: 'all',
        },
    ];

    const [state, setState] = useState({
        requesterName: '',
        competitorStoreCode: paramCompetitorStoreCode ?? '',
        source: '',
        registrationMethod: '',
        checkedValue: [],
    });

    const { requesterName, competitorStoreCode, source, registrationMethod, checkedValue } = state;

    const isDisabled = () => {
        const competitorStoreCodeValid = competitorStoreCode !== '';
        const requesterNameValid = requesterName.trim() !== '';
        const sourceValid = source !== '';
        const registrationMethodValid = registrationMethod !== '';
        const departmentValid = checkedValue.length > 0;
        if (isHeadquarter) {
            return !(requesterNameValid && sourceValid && registrationMethodValid && departmentValid);
        }
        if (isCompetitor) {
            return !(competitorStoreCodeValid && requesterNameValid && sourceValid && registrationMethodValid && departmentValid);
        }
        if (isFreshness || isInstruction) {
            return !(requesterNameValid && departmentValid);
        }
        return false;
    };

    useEffect(() => {
        dispatch(fetchSources());
        dispatch(fetchRegistrationMethods());
        dispatch(fetchDepartments());
        if (isHeadquarter) {
            dispatch(fetchCompetitorStores());
        } else {
            dispatch(fetchCompetitorStoresByStoreCode());
        }
    }, [dispatch]);

    const createCompetitorStoreOptions = () => {
        return (
            competitorStores?.map((item) => ({
                value: item.competitorStoreCode,
                label: item.competitorStoreName,
            })) ?? []
        );
    };

    const handleCheckList = (checked = [], isCheckAll = false) => {
        const checkedValue = [];
        const checkedList = [];

        if (isCheckAll) {
            checked.forEach((value) => {
                if (value === 'all') {
                    checkedValue.push('all');
                    departments.forEach((item) => {
                        checkedValue.push(item?.value ?? '');
                        checkedList.push(item?.value ?? '');
                    });
                } else {
                    checkedValue.length = 0;
                }
            });
        } else {
            checked.forEach((value) => {
                checkedValue?.push(value);
                if (value !== 'all') {
                    checkedList?.push(value);
                }
            });

            const allCheckIndex = checked.indexOf('all');
            if (checkedList?.length === (departments?.length ?? 0)) {
                if (allCheckIndex < 0) {
                    checkedValue?.push('all');
                }
            } else {
                if (allCheckIndex >= 0) {
                    checkedValue?.splice(allCheckIndex, 1);
                }
            }
        }

        setState((prevState) => ({
            ...prevState,
            checkedValue,
        }));
    };

    const handleChangeInput = (field, value) => {
        setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleNextPage = () => {
        if (isDisabled()) return;

        // checkedValueからallを削除
        const newCheckedValue = checkedValue.filter((value) => value !== 'all');
        dispatch(setDepartments(newCheckedValue));
        dispatch(setRequesterName(requesterName));

        if (isCompetitor) {
            dispatch(setCompetitorStore(competitorStoreCode));
            dispatch(setSource(source));
            dispatch(setRegistrationMethod(registrationMethod));
        }

        if (isFreshness) {
            dispatch(setRegistrationMethod('FORM')); // FORM固定
        }

        if (isInstruction) {
            dispatch(setRegistrationMethod('FORM')); // FORM固定
        }

        history('/price-search');
    };

    return (
        <div className="okc-inner">
            <div className="okc-title fade-in">
                {isCompetitor ? t('select_store.title') : isFreshness ? t('freshness.select_store.title') : t('select_store.title')}
            </div>
            <Card type="one_button_footer" disabled={isDisabled()} handleNext={handleNextPage}>
                <div className="no-results-found d-none">{t('common.message.the_store_cannot_be_found')}</div>
                {isCompetitor ? (
                    // 競合店舗選択&入力者名
                    <>
                        <Label
                            type="column"
                            text={t('select_store.okc.card.survey')}
                            className="col-lg-2 mb-2 mb-lg-4 mt-lg-2 align-self-start"
                        />
                        <div className="col-lg-4 pr-lg-3 mb-4">
                            <SelectSearch
                                placeholder={t('select_store.okc.placeholder.survey')}
                                onChange={(target) => {
                                    handleChangeInput('competitorStoreCode', target.value);
                                }}
                                options={createCompetitorStoreOptions()}
                                value={competitorStoreCode}
                            />
                        </div>
                        <Label
                            type="column"
                            text={t('select_store.okc.card.person')}
                            className="col-lg-2 pl-lg-3 mb-2 mb-lg-4 mt-lg-2 align-self-start"
                        />
                        <div className="col-lg-4 pr-lg-3 mb-4">
                            <Input
                                className="form-control form-control--input"
                                id="username"
                                name="requesterName"
                                placeholder={t('select_store.okc.placeholder.person')}
                                type="text"
                                maxLength="45"
                                onChange={(value) => handleChangeInput('requesterName', value)}
                                value={requesterName}
                            />
                        </div>
                    </>
                ) : (
                    // 入力者名
                    <>
                        <Label
                            type="column"
                            text={t('select_store.okc.card.person')}
                            className="col-lg-2 mb-2 mb-lg-4 mt-lg-2 align-self-start"
                        />
                        <div className="col-lg-4 pr-lg-3 mb-4">
                            <Input
                                className="form-control form-control--input"
                                id="username"
                                name="requesterName"
                                placeholder={t('select_store.okc.card.title.person')}
                                type="text"
                                maxLength="45"
                                onChange={(value) => handleChangeInput('requesterName', value)}
                                value={requesterName}
                            />
                        </div>
                        <div className="col-lg-6 pr-lg-3 mb-4"></div>
                    </>
                )}
                {isCompetitor && (
                    // 情報取得元&登録方法
                    <>
                        <Label
                            type="column"
                            text={t('select_store.okc.information_acquisition_source')}
                            className="col-lg-2 mb-2 mb-lg-4 mt-lg-2 align-self-start"
                        />
                        <div className="col-lg-10 mb-4 competitive-store__radios">
                            <div className="row m-0 competitive-store__row js-okc-validate">
                                <Choice
                                    id="source"
                                    column="col-xl-3 col-lg-4 col-md-5"
                                    type="radio"
                                    data={sources}
                                    onChange={(value) => handleChangeInput('source', value[0])}
                                    checked={[source]}
                                />
                            </div>
                        </div>
                        <Label
                            type="column"
                            text={t('select_store.okc.information_registration_method')}
                            className="col-lg-2 mb-2 mb-lg-4 mt-lg-2 align-self-start"
                        />
                        <div className="col-lg-10 mb-4 competitive-store__radios">
                            <div className="row m-0 competitive-store__row js-okc-validate">
                                <Choice
                                    id="registrationMethod"
                                    column="col-xl-3 col-lg-4 col-md-5"
                                    type="radio"
                                    data={registrationMethods}
                                    onChange={(value) => handleChangeInput('registrationMethod', value[0])}
                                    checked={[registrationMethod]}
                                />
                            </div>
                        </div>
                    </>
                )}
                {/* 部門 */}
                <Label
                    type="column"
                    text={t('select_store.okc.card.department')}
                    className="col-lg-2 mb-2 mb-lg-4 mt-lg-2 align-self-start"
                />
                <div className="col-lg mb-4 competitive-store__checkboxes">
                    <div className="row m-0">
                        <Choice
                            column="col-xl-3 col-lg-4 col-md-5"
                            type="checkbox"
                            data={departmentsAll}
                            onChange={(value) => handleCheckList(value, true)}
                            checked={checkedValue ?? []}
                        />
                        <Choice
                            column="col-xl-3 col-lg-4 col-md-5"
                            type="checkbox"
                            data={departments}
                            onChange={(value) => handleCheckList(value)}
                            checked={checkedValue ?? []}
                        />
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default DepartmentSelect;
