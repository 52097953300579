import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setSort, setToastsSuccess } from '../../../redux/actions/common';
import Tabs from '../../common/Tabs';
import Button from '../../common/Button';
import Modal from '../../common/Modal';
import Pagination from '../../common/Pagination';
import Card from '../../common/Card';
import Choice from '../../common/Choice';
import { RECEIVER_TYPE } from '../../../utils/constants/string';
import SelectSearch from '../../common/SelectSearch';
import Input from '../../common/Input';
import { RULE } from '../../../utils/constants/regex';
import EditTable from '../../tables/EditTable';
import { fetchDepartments, setLoader } from '../../../redux/actions/api';
import { EmailReceiverControllerService } from '../../../services';
import { getTableFixedPointObservationTab, getTableNearbyStoreTab, getTableRequestDissociatedTab } from '../../tables/table';
import { setDeleteConfirm, setIsDeleteContinue } from '../../../redux/actions/modal';
import { createDepartmentOptions } from '../../../utils/helpers/option';
import apiErrorHandler from '../../../api/apiErrorHandler';
import { useLocation } from 'react-router-dom';
import { clearErrors } from '../../../redux/actions/error';

const Notification = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();

    const sort = useSelector((state) => state.sort);

    const constants = useSelector((state) => state.constants);
    const { departments } = constants;

    const modal = useSelector((state) => state.modal);
    const { isDeleteContinue } = modal;

    const [state, setState] = useState({
        isShowModalRegister: false,
        selectedReceiverType: 'DISSOCIATED',
        departmentCode: '',
        email: '',
        isCheckEmail: false,

        page: 1,
        limit: 20,
        apiResponse: null,
        receiverType: 'DISSOCIATED',
    });

    const { isShowModalRegister, selectedReceiverType, departmentCode, email, isCheckEmail, page, limit, apiResponse, receiverType } =
        state;

    const [formData, setFormData] = useState(null);

    const handleDeleteClick = (index, value) => {
        setFormData(value);
        dispatch(setDeleteConfirm(true));
    };

    const tableRequestDissociatedTab = getTableRequestDissociatedTab(t, handleDeleteClick);
    const tableNearbyStoreTab = getTableNearbyStoreTab(t, handleDeleteClick);
    const tableFixedPointObservationTab = getTableFixedPointObservationTab(t, handleDeleteClick);

    useEffect(() => {
        dispatch(clearErrors());
        dispatch(setSort());
        dispatch(fetchDepartments());
    }, [dispatch, location]);

    useEffect(() => {
        getEmailReceivers();
    }, [dispatch, receiverType, page, sort]);

    useEffect(() => {
        if (isDeleteContinue) {
            // 削除確認モーダル表示後に処理を続行する場合
            deleteItem();
            dispatch(setIsDeleteContinue(false));
        }
    }, [isDeleteContinue]);

    const getEmailReceivers = () => {
        dispatch(setLoader(true));
        dispatch(clearErrors());
        EmailReceiverControllerService.getEmailReceivers(
            receiverType,
            page,
            limit,
            tableRequestDissociatedTab.heads.find((head) => head.field === sort.sortField)?.field,
            sort.sortType,
        )
            .then((response) => {
                setState((prevState) => ({
                    ...prevState,
                    apiResponse: response,
                }));
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const renderTable = (type) => {
        switch (type) {
            case 'DISSOCIATED':
                return (
                    <EditTable
                        isHeightTr
                        onlyTab
                        heads={tableRequestDissociatedTab.heads}
                        body={tableRequestDissociatedTab.body}
                        dataTable={apiResponse?.data}
                    />
                );
            case 'NEARBY_STORE':
                return (
                    <EditTable
                        isHeightTr
                        onlyTab
                        heads={tableNearbyStoreTab.heads}
                        body={tableNearbyStoreTab.body}
                        dataTable={apiResponse?.data}
                    />
                );
            case 'OBSERVATION':
                return (
                    <EditTable
                        isHeightTr
                        onlyTab
                        heads={tableFixedPointObservationTab.heads}
                        body={tableFixedPointObservationTab.body}
                        dataTable={apiResponse?.data}
                    />
                );
            default:
                return null;
        }
    };

    const handlePageClick = (event) => {
        handleChangeState('page', event.selected + 1);
    };

    const deleteItem = () => {
        dispatch(setLoader(true));
        dispatch(clearErrors());
        EmailReceiverControllerService.deleteEmailReceiver(formData.email, receiverType, formData.departmentCode)
            .then(() => {
                getEmailReceivers();
                dispatch(setToastsSuccess('削除しました。'));
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error, t('message.error.delete'));
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
        setFormData(null);
    };

    const isShowTabMenu = [
        {
            title: t('notification_registration.nav.dissociated_email.title'),
            event: 'DISSOCIATED',
            content: renderTable('DISSOCIATED'),
        },
        {
            title: t('notification_registration.nav.nearby_store_email.title'),
            event: 'NEARBY_STORE',
            content: renderTable('NEARBY_STORE'),
        },
        {
            title: t('notification_registration.nav.fixed_point_observation_email.title'),
            event: 'OBSERVATION',
            content: renderTable('OBSERVATION'),
        },
    ];

    const handleChangeState = (field, value) => {
        setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleShowSubmitModal = () => {
        setState((prevState) => ({
            ...prevState,
            isShowModalRegister: true,
        }));
    };

    const handleCloseModal = () => {
        handleClearForm();
    };

    const handleClearForm = () => {
        setState((prevState) => ({
            ...prevState,
            isShowModalRegister: false,
            selectedReceiverType: 'DISSOCIATED',
            departmentCode: '',
            email: '',
            isCheckEmail: false,
        }));
    };

    const handleSubmitFormNotification = async () => {
        if (validateEmail()) return;

        dispatch(setLoader(true));
        dispatch(clearErrors());
        EmailReceiverControllerService.upsertEmailReceiver({
            receiverType: selectedReceiverType,
            departmentCode: departmentCode,
            email: email,
        })
            .then(() => {
                getEmailReceivers();
                dispatch(setToastsSuccess('登録しました。'));
                handleClearForm();
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error, t('message.error.registration'));
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const validateEmail = () => {
        if (!RULE.EMAIL.test(email)) {
            setState((prevState) => ({
                ...prevState,
                isCheckEmail: true,
            }));
            return true;
        }
        return false;
    };

    const handleShowForm = (value) => {
        handleChangeState('receiverType', value);
    };

    return (
        <>
            <div className="d-flex justify-content-end c-slide-top margin-bottom__18">
                <Button
                    id="submit-button"
                    className="btn btn--primary mr-2"
                    onClick={handleShowSubmitModal}
                    children={<span className="btn-text">{t('user.user-registration.button.register')}</span>}
                />
            </div>
            <div id="pagination" className="d-flex c-slide-top paginate-custom wp-pagination">
                <nav className="okc-pagination--notification okc-pagination--total ml-auto">
                    <p className="okc-pagination__total"></p>
                    <Pagination pageCount={apiResponse?.totalPage} onPageChange={handlePageClick} />
                </nav>
            </div>
            <div className="ok_tab_table">
                <Tabs type="material" tabs={isShowTabMenu} handleClick={(event) => handleShowForm(event)} />
            </div>
            <Modal
                size="xl"
                title={t('notification_registration.registration_modal.header')}
                isShowIconClose="true"
                isShow={isShowModalRegister}
                onClose={handleCloseModal}
                className="registration-edit-modal"
                btnList={[
                    {
                        name: t('notification_registration.registration_modal.ok_button'),
                        className: 'btn--primary',
                        onClick: () => handleSubmitFormNotification(),
                    },
                ]}
            >
                <div className="okc-card okc-result-form">
                    <Card type="modal_card">
                        <div className="row okc-card__row">
                            <div className="col-lg-3 okc-result-form__title notification-form__title">
                                <p>{t('notification_registration.registration_modal.notification')}</p>
                            </div>
                            <div className="col-lg-8 offset-lg-1 d-flex">
                                <div className="row no-gutters w-100 js-okc-validate">
                                    <div className="form-check col-lg-2 pr-lg-3">
                                        <Choice
                                            id="receiver-type-1"
                                            column="col-xl-3 col-lg-4 col-md-5"
                                            type="radio"
                                            data={RECEIVER_TYPE}
                                            onChange={(value) => handleChangeState('selectedReceiverType', value[0])}
                                            checked={[selectedReceiverType]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {selectedReceiverType === 'DISSOCIATED' && (
                            <div className="row okc-card__row">
                                <div className="col-lg-3 okc-result-form__title notification-form__title">
                                    <p>{t('notification_registration.registration_modal.department')}</p>
                                </div>
                                <div className="col-lg-8 offset-lg-1 d-flex">
                                    <SelectSearch
                                        onChange={(option) => {
                                            handleChangeState('departmentCode', option.value);
                                        }}
                                        options={createDepartmentOptions(departments)}
                                        value={departmentCode}
                                        placeholder={t('notification_registration.registration_modal.department')}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="row okc-card__row">
                            <div className="col-lg-3 okc-result-form__title notification-form__title">
                                <p>{t('notification_registration.registration_modal.mail_address')}</p>
                            </div>
                            <div className="col-lg-8 offset-lg-1 d-flex">
                                <div className="row no-gutters w-100">
                                    <div className="col-12 mb-2 js-switch-target" data-switch="2">
                                        <Input
                                            className={`${isCheckEmail ? 'form-control--error' : ''}`}
                                            onChange={(value) => {
                                                handleChangeState('email', value);
                                            }}
                                            value={email}
                                            placeholder={t('notification_registration.registration_modal.mail_address')}
                                        />
                                    </div>
                                    {isCheckEmail && (
                                        <p id="email-error" className="okc-error mt-2 mb-0">
                                            {t('notification_registration.registration_modal.invalid_email')}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            </Modal>
        </>
    );
};

export default Notification;
