import React, { useRef, useState } from 'react';
import Button from './Button';

const TagInput = (props) => {
    const [inputValue, setInputValue] = useState('');
    const [isInputVisible, setIsInputVisible] = useState(false);
    const inputRef = useRef(null);

    const { tags, onTagsChange, placeholder } = props;

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputKeyDown = (e) => {
        if (e.key === 'Tab') {
            setIsInputVisible(false);
        }
    };

    const handleTagRemove = (index) => {
        onTagsChange(tags.filter((_, i) => i !== index));
    };

    const handleContainerClick = () => {
        setIsInputVisible(true);
        setTimeout(() => inputRef.current?.focus(), 0);
    };

    const handleInputBlur = () => {
        setIsInputVisible(false);
        setInputValue('');
    };

    const handleEnter = () => {
        if (inputValue.trim()) {
            onTagsChange([...tags, inputValue.trim()]);
            setInputValue('');
        }

        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    return (
        <>
            <div
                style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    border: `1px solid ${isInputVisible ? '#d66f00' : '#e1e6ea'}`,
                    borderRadius: `${isInputVisible ? '5px 5px 0 0' : '5px'}`,
                    padding: '6px',
                    cursor: 'text',
                    width: '100%',
                    minHeight: '40px',
                }}
                onClick={handleContainerClick}
            >
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '5px',
                    }}
                >
                    {tags.length > 0 ? (
                        tags.map((tag, index) => (
                            <div key={index} className="css-oq5sr-multiValue">
                                <div className="css-18ihjig">{tag}</div>
                                <div
                                    role="button"
                                    className="css-1etn14k"
                                    aria-label="Remove アコレ"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleTagRemove(index);
                                    }}
                                >
                                    <svg
                                        height="14"
                                        width="14"
                                        viewBox="0 0 20 20"
                                        aria-hidden="true"
                                        focusable="false"
                                        className="css-tj5bde-Svg"
                                    >
                                        <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                    </svg>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div style={{ position: 'absolute', top: '10px', left: '15px', color: '#afafaf' }}>
                            <span style={{ fontSize: '14px' }}>{`${placeholder}`}</span>
                        </div>
                    )}
                </div>
            </div>
            {/* 入力フォーム */}
            {isInputVisible && (
                <div
                    style={{
                        position: 'absolute',
                        top: '100%',
                        left: '0',
                        width: '100%',
                        padding: '3px',
                        border: '1px solid #d66f00',
                        backgroundColor: 'white',
                    }}
                >
                    <input
                        ref={inputRef}
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        onBlur={(e) => {
                            const nextFocusElement = e.relatedTarget;
                            if (!nextFocusElement || (nextFocusElement.tagName !== 'INPUT' && nextFocusElement.tagName !== 'BUTTON')) {
                                handleInputBlur(e);
                            }
                        }}
                        placeholder={placeholder}
                        style={{
                            width: 'calc(100% - 88px)',
                            padding: '8px',
                            border: '1px solid #d66f00',
                            outline: 'none',
                            backgroundColor: 'white',
                            zIndex: 10,
                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                        }}
                    />
                    <button
                        type="button"
                        className="btn btn--primary focusable-button"
                        onClick={() => handleEnter()}
                        onKeyDown={handleInputKeyDown}
                        style={{ width: '88px' }}
                    >
                        <span className="btn-text">追加</span>
                    </button>
                </div>
            )}
        </>
    );
};

export default TagInput;
