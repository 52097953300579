import { useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../../assets/css/common/datepicker.css';
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';

const formatDate = (date) => {
    if (!date) return null;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
};

const ReactCalender = (props) => {
    const {
        value,
        className,
        placeholder = 'YYYY/MM/DD',
        onChange,
        classNameInputDateTime = '',
        style,
        minDate,
        maxDate,
        isWeekend,
    } = props;
    const customWeekdayLabels = ['日', '月', '火', '水', '木', '金', '土'];
    const [calendarVisible, setCalendarVisible] = useState(false);
    const calendarRef = useRef(null);
    const inputDatePickerRef = useRef(null);
    const [isTop, setTop] = useState(false);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleDateChange = (date) => {
        if (isValidDate(date)) {
            onChange(formatDate(date));
        } else {
            onChange(null);
        }
        setCalendarVisible(false);
    };

    const handleInputChange = (e) => {
        const date = e.target.value;
        if (date === '') {
            onChange(null);
            return;
        }

        const regex = /^\d{4}\/\d{2}\/\d{2}$/;
        if (regex.test(date)) {
            if (isValidDate(date)) {
                onChange(formatDate(date));
            } else {
                onChange(null);
            }
        } else {
            onChange(date);
        }
        setCalendarVisible(false);
    };

    const handleOutFocus = (eventValue) => {
        if (isValidDate(eventValue)) {
            onChange(eventValue);
        } else {
            const regex = /^\d{4}\/\d{2}\/\d{2}$/;
            if (eventValue.length >= 1 && eventValue.length < 9) {
                onChange(formatDate(new Date()));
            } else if ((eventValue.length >= 9 && eventValue.length < 10) || !eventValue) {
                onChange(null);
            } else if (regex.test(eventValue)) {
                onChange(eventValue);
            }
        }
        setCalendarVisible(false);
    };

    const isValidDate = (date) => {
        return date instanceof Date && !isNaN(date);
    };

    const handleClickOutside = (event) => {
        if (calendarRef.current && !calendarRef.current.contains(event.target)) {
            setCalendarVisible(false);
        }
    };

    const openCalendar = () => {
        let scroll = document.getElementsByClassName('okc-right');
        let inputDatePicker = inputDatePickerRef.current;
        let heightSroll = scroll[0].clientHeight;
        let heightItemTop = inputDatePicker.getBoundingClientRect().top;

        if (heightSroll - heightItemTop < 270 || heightItemTop > 580) {
            setTop(true);
            setCalendarVisible(true);
        } else {
            setTop(false);
            setCalendarVisible(true);
        }
    };

    const handleBlur = (event) => {
        const relatedTarget = event.relatedTarget;
        if (relatedTarget === inputDatePickerRef.current || (calendarRef.current && calendarRef.current.contains(relatedTarget))) {
            return;
        }
        handleOutFocus(event.target.value);
    };

    return (
        <div className="custom-date-picker">
            <input
                ref={inputDatePickerRef}
                className={`form-control form-control--input ${classNameInputDateTime}`}
                type="text"
                value={value}
                onFocus={() => openCalendar()}
                placeholder={placeholder}
                onBlur={handleBlur}
                onChange={handleInputChange}
                style={{ ...style }}
            />
            {calendarVisible && (
                <div ref={calendarRef} className={`calendar-container ${className} ${isTop ? 'calendar-top' : ''}`}>
                    <Calendar
                        tileClassName="custom-background"
                        onChange={handleDateChange}
                        value={value}
                        formatShortWeekday={(locale, date) => customWeekdayLabels[date.getDay()]}
                        formatMonthYear={(locale, date) => format(date, 'yyyy年M月', { locale: ja })}
                        formatDay={(locale, date) => date.getDate()}
                        tileContent={({ date, view }) => {
                            if (view === 'year') {
                                return <div className="month-tile">{format(date, 'LLLL', { locale: ja })}</div>;
                            }

                            if (view === 'century') {
                                const year = date.getFullYear();
                                return <div className="century">{year}</div>;
                            }

                            return null;
                        }}
                        showFixedNumberOfWeeks={true}
                        goToRangeStartOnSelect={false}
                        allowPartialRange={true}
                        calendarType="gregory"
                        maxDate={maxDate}
                        minDate={minDate}
                        tileDisabled={({ date }) => isWeekend && ![5, 6, 0].includes(date.getDay())}
                    />
                </div>
            )}
        </div>
    );
};

export default ReactCalender;
