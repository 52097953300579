import {
    FIFTY_DISCOUNT,
    THIRTY_DISCOUNT,
    TEN_DISCOUNT,
    IS_CONTINUE,
    COMMON,
    LIMIT_OVER,
    DELETE_CONFIRM,
    IS_DELETE_CONTINUE,
    PROHIBITED,
    NBPBKBN,
    EXCEL_VALIDATION,
} from '../actions/type';

const initialState = {
    isContinue: false, // 申請・編集続行
    fiftyDiscount: false, // 50%乖離
    thirtyDiscount: false, // 30%乖離
    tenDiscount: false, // 10%乖離
    isDeleteContinue: false, // 削除続行
    deleteConfirm: false, // 削除確認
    // 下限値超過
    limitOver: {
        isShow: false,
        items: [],
    },
    // 禁止商品
    prohibited: {
        isShow: false,
        items: [],
    },
    // PB/留型
    nbpbkbn: {
        isShow: false,
        items: [],
    },
    // Excelバリデーション
    excelValidation: {
        isShow: false,
        items: [],
    },
    // 共通
    common: {
        isShow: false,
        title: '',
        body: '',
    },
};

const modalReducer = (state = initialState, action) => {
    switch (action.type) {
        case IS_CONTINUE:
            return {
                ...state,
                isContinue: action.data,
            };
        case FIFTY_DISCOUNT:
            return {
                ...state,
                fiftyDiscount: action.data,
            };
        case THIRTY_DISCOUNT:
            return {
                ...state,
                thirtyDiscount: action.data,
            };
        case TEN_DISCOUNT:
            return {
                ...state,
                tenDiscount: action.data,
            };
        case LIMIT_OVER:
            return {
                ...state,
                limitOver: action.data,
            };
        case PROHIBITED:
            return {
                ...state,
                prohibited: action.data,
            };
        case NBPBKBN:
            return {
                ...state,
                nbpbkbn: action.data,
            };
        case EXCEL_VALIDATION:
            return {
                ...state,
                excelValidation: action.data,
            };
        case IS_DELETE_CONTINUE:
            return {
                ...state,
                isDeleteContinue: action.data,
            };
        case DELETE_CONFIRM:
            return {
                ...state,
                deleteConfirm: action.data,
            };
        case COMMON:
            return {
                ...state,
                common: action.data,
            };
        default:
            return state;
    }
};

export { modalReducer };
