import React from 'react';
import CameraControl from './CameraControl';
import Input from './Input';
import CodeReader from './CodeReader';

const InputJan = (props) => {
    const {
        divClass,
        inputClass,
        jan,
        name,
        placeholder,
        id = '',
        type = 'text',
        autocomplete = 'off',
        onChange,
        isCameraOpen,
        onOpen,
        onClose,
    } = props;

    const isIOS = () => {
        return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    };

    return (
        <>
            <div className={divClass}>
                <Input
                    className={inputClass}
                    value={jan}
                    placeholder={placeholder}
                    id={id}
                    type={type}
                    autocomplete={autocomplete}
                    onChange={onChange}
                    name={name}
                />
                {isIOS() && <CameraControl isCameraOpen={isCameraOpen} onOpen={onOpen} onClose={onClose} />}
            </div>
            {isIOS() && <CodeReader onChange={onChange} isShow={isCameraOpen} onClose={onClose} />}
        </>
    );
};

export default InputJan;
