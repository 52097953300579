import * as constant from '../constants/string';

export const getValueAuthority = (label) => {
    const result = constant.AUTHORITY.filter((element) => label === element.label);
    return result[0].value;
};

export const getValueOrganization = (label) => {
    const result = constant.ORGANIZATION.filter((element) => label === element.label);
    return result[0].value;
};
