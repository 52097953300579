import { useTranslation } from 'react-i18next';
import Card from '../../components/common/Card';
import ReactCalender from '../../components/common/Datepicker';
import Label from '../../components/common/Label';
import SelectSearch from '../../components/common/SelectSearch';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { setToastsError } from '../../redux/actions/common';
import env from '../../configs/env';
import { fetchChangeRequestType, fetchDepartments, fetchDivisions, fetchStores } from '../../redux/actions/api';
import { createStoreOptions } from '../../utils/helpers/option';
import { clearErrors } from '../../redux/actions/error';
import { ExportControllerService } from '../../services';
import apiErrorHandler from '../../api/apiErrorHandler';

const ExportChangeRequest = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const storage = useSelector((state) => state.storage);
    const { storeCode, isFreshness, isStore } = storage;

    const constants = useSelector((state) => state.constants);
    const { stores, divisions, changeRequestTypes } = constants;

    const DEFAULT_STATE = {
        selectedStoreCode: storeCode || '',
        departments: [],
        lines: [],
        classes: [],
        selectedDivision: null,
        selectedDepartment: null,
        selectedLine: null,
        selectedSubclass: null,
        changeRequestType: isFreshness ? 'FRESHNESS' : 'COMPETITOR',
        startDateMin: '',
        startDateMax: '',
    };

    const [state, setState] = useState(DEFAULT_STATE);

    const {
        selectedStoreCode,
        departments,
        lines,
        classes,

        selectedDepartment,
        selectedLine,
        selectedClass,
        changeRequestType,
        startDateMin,
        startDateMax,
    } = state;

    useEffect(() => {
        dispatch(clearErrors());
        dispatch(fetchDepartments());
        dispatch(fetchDivisions());
        dispatch(fetchStores());
        dispatch(fetchChangeRequestType());

        // デフォルトの部門を対象とする
        handleChangeState(
            'selectedDepartments',
            departments.map((department) => {
                return department.value;
            }),
        );
    }, []);

    useEffect(() => {
        if (divisions) {
            const departments = divisions
                .filter((item) => item.departmentCode > 0 && item.lineCode === 0 && item.classCode === 0 && item.subclassCode === 0)
                .map((item) => {
                    return {
                        ...item,
                        value: item.departmentCode,
                        label: item.divisionName,
                    };
                });
            setState((prevState) => ({
                ...prevState,
                departments,
            }));
        }
    }, [divisions]);

    useEffect(() => {
        const filteredDivisions = divisions?.filter((item) => {
            return item.departmentCode === selectedDepartment && item.lineCode > 0 && item.classCode === 0 && item.subclassCode === 0;
        });

        const lines = filteredDivisions
            .sort((a, b) => a.lineCode - b.lineCode)
            .map((item) => {
                return {
                    ...item,
                    value: item.lineCode,
                    label: item.divisionName,
                };
            });

        setState((prevState) => ({
            ...prevState,
            filteredDivisions,
            lines,
            selectedLine: null,
            selectedClass: null,
            selectedSubclass: null,
        }));
    }, [divisions, selectedDepartment]);

    useEffect(() => {
        const filteredDivisions = divisions?.filter((item) => {
            return (
                item.departmentCode === selectedDepartment &&
                item.lineCode === selectedLine &&
                item.classCode > 0 &&
                item.subclassCode === 0
            );
        });

        const classes = filteredDivisions
            .sort((a, b) => a.classCode - b.classCode)
            .map((item) => {
                return {
                    ...item,
                    value: item.classCode,
                    label: item.divisionName,
                };
            });

        setState((prevState) => ({
            ...prevState,
            filteredDivisions,
            classes,
            selectedClass: null,
            selectedSubclass: null,
        }));
    }, [divisions, lines, selectedDepartment, selectedLine]);

    const getChangeRequestTypes = () => {
        if (changeRequestTypes.length === 0) {
            return [];
        }
        if (isStore) {
            if (isFreshness) {
                return [changeRequestTypes.find((item) => item.value === 'FRESHNESS')];
            } else {
                return [changeRequestTypes.find((item) => item.value === 'COMPETITOR')];
            }
        } else {
            return changeRequestTypes.filter((item) => item.value !== 'INSTRUCTION');
        }
    };

    const handleChangeState = (field, value) => {
        setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleClearForm = () => {
        setState({
            ...DEFAULT_STATE,
            lines,
        });
    };

    const handleSubmitForm = () => {
        if (storeCode === '' || selectedDepartment === '' || startDateMax === '' || startDateMin === '') {
            dispatch(setToastsError(t('export_summary.error-message')));
        } else {
            handleExportFile();
        }
    };

    const handleExportFile = () => {
        // バックエンドからダウンロードするため、formを作成してリクエスト
        const form = document.createElement('form');
        form.action = `${env.BACKEND_BASE_URL}/api/export-change-request`;
        form.method = 'POST';
        document.body.append(form);

        form.addEventListener('formdata', (e) => {
            const fd = e.formData;
            for (const key in state) {
                if (state[key] !== '') {
                    fd.append(key, state[key]);
                }
            }
        });

        ExportControllerService.validateExportChangeRequest(state)
            .then(() => {
                form.submit();
                handleClearForm();
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error);
            })
            .finally(() => {
                form.remove();
            });
    };
    return (
        <div className="export-change-request">
            <div className="okc-inner">
                <Card handleSubmit={handleSubmitForm} handleClear={handleClearForm} isExport={true} toggle={false}>
                    <Label type="column" text={t('update_competitor_master.search_form.ok_store.text')} className="col-lg-2 mb-2 mb-lg-4" />
                    <div className="col-lg-4 pr-lg-3 mb-4">
                        <SelectSearch
                            onChange={(option) => handleChangeState('selectedStoreCode', option.value)}
                            options={createStoreOptions(stores)}
                            value={selectedStoreCode}
                            placeholder={t('price_request.oke_store_name.placeholder')}
                            disabled={isStore}
                        />
                    </div>
                    <Label
                        type="column"
                        text={t('approved_registration_price.search_form.division_name.title')}
                        className="col-lg-2 pl-lg-3 mb-2 mb-lg-4"
                    />
                    <div className="col-lg-4 mb-4" data-select2-id="select2-data-36-36wo">
                        <SelectSearch
                            options={departments}
                            placeholder={t('prohibited_product_list.search_form.division.placeholder')}
                            onChange={(target) => {
                                handleChangeState('selectedDivision', target);
                                handleChangeState('selectedDepartment', target.value);
                            }}
                            value={selectedDepartment}
                        />
                    </div>
                    {/* 売変理由 */}
                    <Label type="column" text="売変理由" className="col-lg-2 mb-2 mb-lg-4" />
                    <div className="col-lg-4 mb-4">
                        <SelectSearch
                            placeholder="売変理由を選択してください"
                            options={getChangeRequestTypes()}
                            onChange={(target) => handleChangeState('changeRequestType', target.value)}
                            value={changeRequestType}
                            disabled={isStore}
                        />
                    </div>
                    <Label
                        type="column"
                        text={t('price_registration.search_form.line_name.title')}
                        className="col-lg-2 pl-lg-3 mb-2 mb-lg-4"
                    />
                    <div className="col-lg-4 mb-4">
                        <SelectSearch
                            options={lines}
                            placeholder={t('price_registration.search_form.line_name.placeholder')}
                            onChange={(target) => {
                                handleChangeState('selectedDivision', target);
                                handleChangeState('selectedLine', target.value);
                            }}
                            value={selectedLine}
                            disabled={!selectedDepartment}
                        />
                    </div>
                    <Label type="column" className="col-lg-2 mb-2 mb-lg-4" />
                    <div className="col-lg-4 pr-lg-3 mb-4"></div>
                    <Label
                        type="column"
                        text={t('price_registration.search_form.class_name.title')}
                        className="col-lg-2 pl-lg-3 mb-2 mb-lg-4"
                    />
                    <div className="col-lg-4 mb-4">
                        <SelectSearch
                            options={classes}
                            placeholder={t('price_registration.search_form.class_name.placeholder')}
                            onChange={(target) => {
                                handleChangeState('selectedDivision', target);
                                handleChangeState('selectedClass', target.value);
                            }}
                            value={selectedClass}
                            disabled={!selectedLine}
                        />
                    </div>
                    <Label className="col-lg-2 mb-2 mb-lg-4" type="column" text="売変開始日" />
                    <div className="col-lg-4 mb-4">
                        <ReactCalender
                            classNameInputDateTime="js-datepicker startDateMin"
                            onChange={(value) => handleChangeState('startDateMin', value)}
                            value={startDateMin}
                        />
                    </div>
                    <div className="col-lg-2 justify-content-center mb-4">〜</div>
                    <div className="col-lg-4 mb-4">
                        <ReactCalender
                            classNameInputDateTime="js-datepicker startDateMax"
                            onChange={(value) => handleChangeState('startDateMax', value)}
                            value={startDateMax}
                        />
                    </div>
                </Card>
            </div>
        </div>
    );
};
export default ExportChangeRequest;
