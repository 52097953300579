/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type AdminRequest = {
    email?: string;
    storeCode?: string;
    authority?: AdminRequest.authority;
    organization?: AdminRequest.organization;
    registerValid?: boolean;
    updateValid?: boolean;
};
export namespace AdminRequest {
    export enum authority {
        ADMIN = 'ADMIN',
        MANAGER = 'MANAGER',
        EDITOR = 'EDITOR',
    }
    export enum organization {
        STORE = 'STORE',
        HEADQUARTERS = 'HEADQUARTERS',
        IT_HEADQUARTERS = 'IT_HEADQUARTERS',
    }
}

