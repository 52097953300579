import React from 'react';
import CameraOff from '../../assets/images/camera-off.svg';
import camera from '../../assets/images/camera.svg';

const CameraControl = ({ isCameraOpen, onOpen, onClose }) => {
    return (
        <div style={{ cursor: 'pointer' }}>
            {isCameraOpen ? (
                <img id="close-camera" className="form-cam" src={CameraOff} alt="Close Camera" onClick={onClose} />
            ) : (
                <img id="open-camera" className="form-cam" src={camera} alt="Open Camera" onClick={onOpen} />
            )}
        </div>
    );
};

export default CameraControl;
