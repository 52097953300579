import '../../assets/css/components/side-bar.css';
import { Link, useLocation } from 'react-router-dom';
import * as routes from '../../utils/constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { AdminRequest, MenuControllerService, PriceSearchRequest } from '../../services';
import apiErrorHandler from '../../api/apiErrorHandler';
import { clearErrors } from '../../redux/actions/error';

const SideBar = () => {
    const dispatch = useDispatch();
    const loader = useSelector((state) => state.loader);
    const storage = useSelector((state) => state.storage);
    const { t } = useTranslation();
    const location = useLocation();
    const {
        organization,
        changeRequestType,
        isCompetitor,
        isStore,
        isHeadquarter,
        isItHeadquarter,
        // isAdmin,
        isManager,
        // isEditor
    } = storage;

    const [badges, setBadges] = useState(null);
    const [userPrincipal, setUserPrincipal] = useState(null);
    useEffect(() => {
        dispatch(clearErrors());
    }, [location, dispatch]);

    useEffect(() => {
        dispatch(clearErrors());
        MenuControllerService.getBadges(changeRequestType)
            .then((response) => {
                setBadges(response);
                setUserPrincipal(response.userPrincipal);
            })
            .catch((error) => {
                if (error.body?.message) {
                    apiErrorHandler(dispatch, error);
                }
            });
    }, [changeRequestType, loader]);

    const isShowBadge = (item) => {
        return badges && item.field && badges[item.field] > 0;
    };

    const getChangeRequestTypeRoutes = () => {
        if (isItHeadquarter) {
            return [];
        }
        switch (changeRequestType) {
            case PriceSearchRequest.changeRequestType.COMPETITOR:
                return isManager ? routes.COMPETITOR_MANAGER : routes.COMPETITOR_EDITOR;
            case PriceSearchRequest.changeRequestType.FRESHNESS:
                return isManager ? routes.FRESHNESS_MANAGER : routes.FRESHNESS_EDITOR;
            case PriceSearchRequest.changeRequestType.INSTRUCTION:
                return isManager ? routes.INSTRUCTION_MANAGER : routes.INSTRUCTION_EDITOR;
            default:
                return [];
        }
    };

    const getOrganizationRoutes = () => {
        switch (organization) {
            case AdminRequest.organization.STORE:
                return isCompetitor ? routes.STORE_COMPETITOR_ONLY : [];
            case AdminRequest.organization.HEADQUARTERS:
                return routes.HEADQUARTER_ONLY;
            case AdminRequest.organization.IT_HEADQUARTERS:
                return routes.IT_HEADQUARTER_ONLY;
            default:
                return [];
        }
    };

    return (
        <div id="sideBar" className="col-auto okc-left">
            <div className="okc-sidebar sb-slide-right okc-sidebar--compact">
                <div className="okc-sidebar__inner">
                    <div className="okc-sidebar__section okc-sidebar__head">
                        <div className="okc-sidebar__logo"></div>
                        <div className="okc-sidebar__section-right okc-sidebar__title">
                            {!isHeadquarter ? userPrincipal?.storeName : '店舗運営本部'}
                        </div>
                    </div>
                    <div className="okc-sidebar__section">
                        <ul className="okc-sidebar__list">
                            <li className="okc-sidebar__item">
                                <Link className="okc-sidebar__link active" to="/">
                                    <i className="icon-user okc-sidebar__icon"></i>
                                    <div className="okc-sidebar__text">{userPrincipal?.email}</div>
                                </Link>
                            </li>
                            <li className="okc-sidebar__item">
                                <div className="okc-sidebar__link">
                                    <i className="icon-home okc-sidebar__icon"></i>
                                    <div className="okc-sidebar__text">
                                        {isHeadquarter ? '店舗運営本部' : isItHeadquarter ? 'IT本部' : userPrincipal?.storeName}
                                    </div>
                                </div>
                            </li>
                            <li className="okc-sidebar__item">
                                <a className="okc-sidebar__link" href="/login">
                                    <i className="icon-exit okc-sidebar__icon"></i>
                                    <div className="okc-sidebar__text">{t('sidebar.logout')}</div>
                                </a>
                            </li>
                            {isStore && (
                                <>
                                    <li className="okc-sidebar__item">
                                        <Link className="okc-sidebar__link" to="/select-menu">
                                            <i className="icon-circle okc-sidebar__icon"></i>
                                            <div className="okc-sidebar__text">{t('sidebar.top')}</div>
                                        </Link>
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                    <div className="okc-sidebar__section">
                        <ul className="okc-sidebar__list">
                            {getChangeRequestTypeRoutes().map((item, key) => (
                                <li key={key} className="okc-sidebar__item">
                                    <Link className="okc-sidebar__link" to={item.href}>
                                        <div className="okc-sidebar__text-wrapper">
                                            <i className="icon-circle okc-sidebar__icon"></i>
                                            <div className="okc-sidebar__text">
                                                <span className="okc-sidebar__content">{item.name}</span>
                                                {isShowBadge(item) && (
                                                    <span className="badge okc-badge attention-animate">
                                                        <span id="survey-history-link">{badges[item.field]}</span>
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            ))}
                            {getOrganizationRoutes().map((item, key) => (
                                <li key={key} className="okc-sidebar__item">
                                    <Link className="okc-sidebar__link" to={item.href}>
                                        <div className="okc-sidebar__text-wrapper">
                                            <i className="icon-circle okc-sidebar__icon"></i>
                                            <div className="okc-sidebar__text">
                                                <span className="okc-sidebar__content">{item.name}</span>
                                                {isShowBadge(item) && (
                                                    <span className="badge okc-badge attention-animate">
                                                        <span id="survey-history-link">{badges[item.field]}</span>
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SideBar;
